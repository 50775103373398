<template>
  <div>
    <a href="https://symone.fr/moto" target="_blank">
      <img
        src="@/assets/images/ui/logo_couleur (1).png"
        alt="Logo"
        style="
          width: 400px;
          align-self: center;
          padding: 10px;
        "
      />
    </a>
    <div
      style="
        background: #000047;
        height: 50px;
        border-bottom: 3px solid #e6007e;
      "
    >
      <div
        class="menu-admin"
        :class="{ active: currentTab === 'events' }"
        @click="switchTab('events')"
      >
        Events
      </div>
      <div
        class="menu-admin"
        :class="{ active: currentTab === 'trajectories' }"
        @click="switchTab('trajectories')"
      >
        Trajets
      </div>
      <div
        class="menu-admin"
        :class="{ active: currentTab === 'news' }"
        @click="switchTab('news')"
      >
        Actus
      </div>
      <div
        class="menu-admin"
        :class="{ active: currentTab === 'users' }"
        @click="switchTab('users')"
      >
        Clients
      </div>
      <div
        class="menu-admin"
        :class="{ active: currentTab === 'costing' }"
        @click="switchTab('costing')"
      >
        Chiffrage
      </div>
      <!-- <div
        class="menu-admin"
        :class="{ active: currentTab === 'users' }"
        @click="switchTab('users')"
      >
        Users
      </div>
      <div
        class="menu-admin"
        :class="{ active: currentTab === 'orders' }"
        @click="switchTab('orders')"
      >
        Chiffrage
      </div> -->
    </div>

    <!-- Tab Users -->
    <div v-if="currentTab === 'users'"></div>




    <!-- Tab Costing -->
    <div v-if="currentTab === 'costing'"></div>

    <div v-if="currentTab === 'events'">
      <el-table v-if="events.length > 0" :data="events" stripe>
        <el-table-column type="expand">
          <template #default="props">
            <div class="tr-edit">
              <img
                v-if="props.row.img"
                :src="props.row.img"
                alt=""
              />
              <br /><br />
              <input
                type="text"
                v-model="
                  tmpEvents[events.findIndex((item) => item.id === props.row.id)]
                    .img
                "
                placeholder="Image d'event(url)"
              />
              <br /><br />
              <input
                type="text"
                v-model="
                  tmpEvents[events.findIndex((item) => item.id === props.row.id)]
                    .name
                "
                placeholder="Nom d'event"
              />
              <br /><br />
              <input
                type="text"
                v-model="
                  tmpEvents[events.findIndex((item) => item.id === props.row.id)]
                    .location
                "
                placeholder="Lieu d'event(Ville)"
              />
              <br /><br />
              <div class="editor-con">
                <textarea
                  v-model="
                    tmpEvents[events.findIndex((item) => item.id === props.row.id)]
                      .details
                  "
                ></textarea>
              </div>
              <br /><br>
              <input
                type="text"
                v-model="
                  tmpEvents[events.findIndex((item) => item.id === props.row.id)]
                    .link
                "
                placeholder="Lien d'event(url)"
              />
              <br /><br />
              Date de début 
              <input
                type="date"
                v-model="tmpEvents[events.findIndex((item) => item.id === props.row.id)].date_start"
              />
              <br /><br />
              Date de fin 
              <input
                type="date"
                v-model="tmpEvents[events.findIndex((item) => item.id === props.row.id)].date_end"
              />
              <br /><br />
              <div class="flex space-between">
                <button class="btn-blue" @click="confirmDelEvent(props.row)">
                  Supprimer
                </button>
                <button class="btn-blue" @click="editEventSend(props.row)">
                  Modifier
                </button>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Date de début">
          <template #default="{ row }">
            {{ formattedDate(events[events.findIndex((item) => item.id === row.id)].date_start) }}
          </template>
        </el-table-column>
        <el-table-column label="Date de fin">
          <template #default="{ row }">
            {{ formattedDate(events[events.findIndex((item) => item.id === row.id)].date_end) }}
          </template>
        </el-table-column>
        <el-table-column label="Nom d'event" prop="name" />
        <el-table-column label="Location" prop="location" />
      </el-table>
      <hr>
      <div class="news-add">
        <b>Ajouter un event</b><br><br>
      <form id="formEvent">
        <input
          type="text"
          v-model="formDataEvent.link"
          placeholder="Event URL"
        /><br>
        <input
          type="text"
          v-model="formDataEvent.img"
          placeholder="Event Img URL"
        /><br>
        <img
          v-if="formDataEvent.img"
          :src="formDataEvent.img"
          alt=""
        /><br>
        <input
          type="text"
          v-model="formDataEvent.name"
          placeholder="Event Name"
        />
        <input
          type="text"
          v-model="formDataEvent.location"
          placeholder="Event Location"
        /><br>
        <textarea
          type="text"
          v-model="formDataEvent.details"
          placeholder="Event Description"
        ></textarea>
        <br><br>
        Date de début  
        <input
          type="date"
          v-model="formDataEvent.date_start"
        /><br>
        Date de fin 
        <input
          type="date"
          v-model="formDataEvent.date_end"
        /><br><br>
        <button type="button" class="btn-blue" @click='addEventSend()'>
          Ajouter
        </button>
      </form>
    </div>
    </div>
    <div v-else-if="currentTab === 'trajectories' ">
      <el-table v-if="trajectories.length > 0" :data="trajectories" :default-sort="{ prop: 'date_depart', order: 'descending' }" stripe>
        <el-table-column type="expand">
          <template #default="props">
            <div class="tr-edit">
              <form>
                Date de départ:
                <input
                  type="date"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].date_depart
                  "
                  placeholder="Date de départ"
                  require
                />
                <br />
                <br />
                Complet ? :
                <input
                  type="checkbox"
                  id="completed"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].completed
                  "
                  value="true"
                />
                <br />
                <br />
                <div style="max-width: 475px">
                  <Autocomplete :required="true" 
                    v-model="
                      tmpTrajectories[
                        trajectories.findIndex((item) => item.id === props.row.id)
                      ].depart
                    "
                    placeholder="Départ"
                    :require="true"
                    class="autocomplete"
                  />
                </div>
                <input
                  type="text"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].address_depart
                  "
                  placeholder="Adresse"
                  class="autocomplete"
                />
                <input
                  type="time"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].time0
                  "
                />
                <br />
                <div style="max-width: 475px">
                  <Autocomplete :required="true" 
                    v-model="
                      tmpTrajectories[
                        trajectories.findIndex((item) => item.id === props.row.id)
                      ].arrival
                    "
                    placeholder="Destination"
                    :require="true"
                    class="autocomplete"
                  ></Autocomplete>
                </div>
                <input
                  type="text"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].address_arrival
                  "
                  placeholder="Adresse"
                  class="autocomplete"
                />
                <input
                  type="time"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].time4
                  "
                />
                <br />
                <div style="max-width: 475px">
                  <Autocomplete
                    v-model="
                      tmpTrajectories[
                        trajectories.findIndex((item) => item.id === props.row.id)
                      ].layover1
                    "
                    placeholder="Escale 1"
                    class="autocomplete"
                  ></Autocomplete>
                </div>
                <input
                  type="text"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].address_layover1
                  "
                  placeholder="Adresse"
                  class="autocomplete"
                />
                <input
                  type="time"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].time1
                  "
                />
                <br />
                <div style="max-width: 475px">
                  <Autocomplete
                    v-model="
                      tmpTrajectories[
                        trajectories.findIndex((item) => item.id === props.row.id)
                      ].layover2
                    "
                    placeholder="Escale 2"
                    class="autocomplete"
                  ></Autocomplete>
                </div>
                <input
                  type="text"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].address_layover2
                  "
                  placeholder="Adresse"
                  class="autocomplete"
                />
                <input
                  type="time"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].time2
                  "
                />
                <br />
                <div style="max-width: 475px">
                  <Autocomplete
                    v-model="
                      tmpTrajectories[
                        trajectories.findIndex((item) => item.id === props.row.id)
                      ].layover3
                    "
                    placeholder="Escale 3"
                    class="autocomplete"
                  ></Autocomplete>
                </div>
                <input
                  type="text"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].address_layover3
                  "
                  placeholder="Adresse"
                  class="autocomplete"
                />
                <input
                  type="time"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].time3
                  "
                />
                <br />
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].depart
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].depart.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].arrival
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].arrival.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price0_4
                  "
                  placeholder="Prix"
                /><span v-if="tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value" style='color: #c50909;'><b>{{ tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_type == '1' ? parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price0_4) * (1 - (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value) / 100)) : (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price0_4) - parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value)) }}</b>€</span>
                <br />
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].depart
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].depart.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].layover1
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover1.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price0_1
                  "
                  placeholder="Prix"
                /><span v-if="tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value" style='color: #c50909;'><b>{{ tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_type == '1' ? parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price0_1) * (1 - (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value) / 100)) : (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price0_1) - parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value)) }}</b>€</span>
                <br />
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].depart
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].depart.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].layover2
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover2.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price0_2
                  "
                  placeholder="Prix"
                /><span v-if="tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value" style='color: #c50909;'><b>{{ tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_type == '1' ? parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price0_2) * (1 - (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value) / 100)) : (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price0_2) - parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value)) }}</b>€</span>
                <br />
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].depart
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].depart.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].layover3
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover3.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price0_3
                  "
                  placeholder="Prix"
                /><span v-if="tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value" style='color: #c50909;'><b>{{ tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_type == '1' ? parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price0_3) * (1 - (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value) / 100)) : (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price0_3) - parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value)) }}</b>€</span>
                <br />
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].layover1
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover1.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].layover2
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover2.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price1_2
                  "
                  placeholder="Prix"
                /><span v-if="tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value" style='color: #c50909;'><b>{{ tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_type == '1' ? parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price1_2) * (1 - (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value) / 100)) : (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price1_2) - parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value)) }}</b>€</span>
                <br />
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].layover1
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover1.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].layover3
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover3.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price1_3
                  "
                  placeholder="Prix"
                /><span v-if="tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value" style='color: #c50909;'><b>{{ tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_type == '1' ? parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price1_3) * (1 - (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value) / 100)) : (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price1_3) - parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value)) }}</b>€</span>
                <br />
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].layover1
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover1.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].arrival
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].arrival.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price1_4
                  "
                  placeholder="Prix"
                /><span v-if="tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value" style='color: #c50909;'><b>{{ tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_type == '1' ? parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price1_4) * (1 - (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value) / 100)) : (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price1_4) - parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value)) }}</b>€</span>
                <br />
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].layover2
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover2.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].layover3
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover3.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price2_3
                  "
                  placeholder="Prix"
                /><span v-if="tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value" style='color: #c50909;'><b>{{ tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_type == '1' ? parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price2_3) * (1 - (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value) / 100)) : (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price2_3) - parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value)) }}</b>€</span>
                <br />
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].layover2
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover2.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].arrival
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].arrival.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price2_4
                  "
                  placeholder="Prix"
                /><span v-if="tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value" style='color: #c50909;'><b>{{ tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_type == '1' ? parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price2_4) * (1 - (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value) / 100)) : (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price2_4) - parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value)) }}</b>€</span>
                <br />
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].layover3
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover3.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpTrajectories[
                    trajectories.findIndex((item) => item.id === props.row.id)
                  ].arrival
                    ? tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].arrival.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price3_4
                  "
                  placeholder="Prix"
                /><span v-if="tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value" style='color: #c50909;'><b>{{ tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_type == '1' ? parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price3_4) * (1 - (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value) / 100)) : (parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].price3_4) - parseInt(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value)) }}</b>€</span>
                <br />
                <br />
                Nombre de moto réservé/max
                <input
                  type="number"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].moto_reserved
                  "
                  placeholder="Moto reservé"
                  require
                />
                /
                <input
                  type="number"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].moto_max
                  "
                  placeholder="Moto max"
                  require
                />
                <br />
                Nombre de voyageur réservé/max
                <input
                  type="number"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].passenger_reserved
                  "
                  placeholder="Voyageur reservé"
                  require
                />
                /
                <input
                  type="number"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].passenger_max
                  "
                  placeholder="Voyageur max"
                  require
                />
                <br />
                <br />
                <input
                  type="number"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_value
                  "
                  placeholder="Combien de réduction ?"
                />
                <select
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_type
                  "
                >
                  <option value="1">%</option>
                  <option value="2">€</option></select
                >de réduction
                <br />
                <br />
                Tarif groupe:
                <input
                  type="number"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_group_value
                  "
                  placeholder="Combien de réduction ?"
                />% pour ≥
                <input
                  type="number"
                  v-model="
                    tmpTrajectories[
                      trajectories.findIndex((item) => item.id === props.row.id)
                    ].discount_group_moto
                  "
                />
                moto
                <br />
                <br />
                <div class="editor-con">
                  Economie<br />
                  <QuillEditor
                    :options="options"
                    v-model:content="
                      tmpTrajectories[
                        trajectories.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].eco
                    "
                    contentType="html"
                  />
                </div>
                <br /><br />
                <div class="flex space-between">
                  <button
                    class="btn-blue"
                    type="button"
                    @click="confirmDelTrajectory(props.row)"
                  >
                    Supprimer
                  </button>
                  <button
                    type="button"
                    class="btn-blue"
                    @click="editTrajectorySend(props.row)"
                  >
                    Modifier
                  </button>
                </div>
              </form>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop='id' label="ID" sortable>
        </el-table-column>
        <el-table-column prop='date_depart' label="Date de départ" sortable>
          <template #default="{ row }">
            {{ formattedDate(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === row.id)
                    ].date_depart) }}
          </template>
        </el-table-column>
        <el-table-column label="Trajet" prop='depart' sortable>
          <template #default="{ row }">
            {{
              `${tmpTrajectories[
                      trajectories.findIndex((item) => item.id === row.id)
                    ].depart.split(",")[0]}${
                      tmpTrajectories[
                      trajectories.findIndex((item) => item.id === row.id)
                    ].layover1 ? " → " + tmpTrajectories[
                      trajectories.findIndex((item) => item.id === row.id)
                    ].layover1.split(",")[0] : ""
              }${tmpTrajectories[
                      trajectories.findIndex((item) => item.id === row.id)
                    ].layover2 ? " → " + tmpTrajectories[
                      trajectories.findIndex((item) => item.id === row.id)
                    ].layover2.split(",")[0] : ""}${
                      tmpTrajectories[
                      trajectories.findIndex((item) => item.id === row.id)
                    ].layover3 ? " → " + tmpTrajectories[
                      trajectories.findIndex((item) => item.id === row.id)
                    ].layover3.split(",")[0] : ""
              } → ${tmpTrajectories[
                      trajectories.findIndex((item) => item.id === row.id)
                    ].arrival.split(",")[0]}`
            }}
          </template>
        </el-table-column>
        <el-table-column prop='date_creation' label="Date de création" sortable>
          <template #default="{ row }">
            {{ formattedDate(tmpTrajectories[
                      trajectories.findIndex((item) => item.id === row.id)
                    ].date_creation) }}
          </template>
        </el-table-column>
        <el-table-column label="Opération">
          <template #default="{ row }">
            <button class="btn-blue" @click="duplicateTrajectory(row)">
              Dupliquer
            </button>
          </template>
        </el-table-column>
      </el-table>
      <hr />
      <div class="news-add">
        <b>Ajouter un trajet</b><br><br>
      <form id="formTrajectory" @submit.prevent="submitForm">
        Date de départ:
        <input
          type="date"
          v-model="formDataTrajectory.date_depart"
          placeholder="Date de départ"
        />
        <br />
        <br />
        Complet ? :
        <input
          type="checkbox"
          id="completed"
          v-model="formDataTrajectory.completed"
          value="true"
        />
        <br />
        <br />
        <div style="max-width: 475px">
          <Autocomplete :required="true" 
            v-model="formDataTrajectory.depart"
            placeholder="Départ"
            class="autocomplete"
            :key="refreshAutocomplete"
          />
        </div>
        <input
          type="text"
          v-model="formDataTrajectory.address_depart"
          placeholder="Adresse"
          class="autocomplete"
        />
        <input type="time" v-model="formDataTrajectory.time0" />
        <br />
        <div style="max-width: 475px">
          <Autocomplete :required="true" 
            v-model="formDataTrajectory.arrival"
            placeholder="Destination"
            class="autocomplete"
            :key="refreshAutocomplete"
          />
        </div>
        <input
          type="text"
          v-model="formDataTrajectory.address_arrival"
          placeholder="Adresse"
          class="autocomplete"
        />
        <input type="time" v-model="formDataTrajectory.time4" />
        <br />
        <div style="max-width: 475px">
          <Autocomplete
            v-model="formDataTrajectory.layover1"
            placeholder="Escale 1"
            @change="console.log(111, formDataTrajectory.layover1)"
            class="autocomplete"
            :key="refreshAutocomplete"
          />
        </div>
        <input
          type="text"
          v-model="formDataTrajectory.address_layover1"
          placeholder="Adresse"
          class="autocomplete"
        />
        <input type="time" v-model="formDataTrajectory.time1" />
        <br />
        <div style="max-width: 475px">
          <Autocomplete
            v-model="formDataTrajectory.layover2"
            placeholder="Escale 2"
            class="autocomplete"
            :key="refreshAutocomplete"
          />
        </div>
        <input
          type="text"
          v-model="formDataTrajectory.address_layover2"
          placeholder="Adresse"
          class="autocomplete"
        />
        <input type="time" v-model="formDataTrajectory.time2" />
        <br />
        <div style="max-width: 475px">
          <Autocomplete 
            v-model="formDataTrajectory.layover3"
            placeholder="Escale 3"
            class="autocomplete"
            :key="refreshAutocomplete"
          />
        </div>
        <input
          type="text"
          v-model="formDataTrajectory.address_layover3"
          placeholder="Adresse"
          class="autocomplete"
        />
        <input type="time" v-model="formDataTrajectory.time3" />
        <br />
        {{
          formDataTrajectory.depart
            ? formDataTrajectory.depart.split(",")[0] + " - "
            : "N - "
        }}
        {{
          formDataTrajectory.arrival
            ? formDataTrajectory.arrival.split(",")[0] + " : "
            : "N : "
        }}
        <input
          type="text"
          v-model="formDataTrajectory.price0_4"
          placeholder="Prix"
        /><span v-if="formDataTrajectory.discount_value" style='color: #c50909;'><b>{{ formDataTrajectory.discount_type == '1' ? parseInt(formDataTrajectory.price0_4) * (1 - (parseInt(formDataTrajectory.discount_value) / 100)) : (parseInt(formDataTrajectory.price0_4) - parseInt(formDataTrajectory.discount_value)) }}</b>€</span>
        <br />
        {{
          formDataTrajectory.depart
            ? formDataTrajectory.depart.split(",")[0] + " - "
            : "N - "
        }}
        {{
          formDataTrajectory.layover1
            ? formDataTrajectory.layover1.split(",")[0] + " : "
            : "N : "
        }}
        <input
          type="text"
          v-model="formDataTrajectory.price0_1"
          placeholder="Prix"
        /><span v-if="formDataTrajectory.discount_value" style='color: #c50909;'><b>{{ formDataTrajectory.discount_type == '1' ? parseInt(formDataTrajectory.price0_1) * (1 - (parseInt(formDataTrajectory.discount_value) / 100)) : (parseInt(formDataTrajectory.price0_1) - parseInt(formDataTrajectory.discount_value)) }}</b>€</span>
        <br />
        {{
          formDataTrajectory.depart
            ? formDataTrajectory.depart.split(",")[0] + " - "
            : "N - "
        }}
        {{
          formDataTrajectory.layover2
            ? formDataTrajectory.layover2.split(",")[0] + " : "
            : "N : "
        }}
        <input
          type="text"
          v-model="formDataTrajectory.price0_2"
          placeholder="Prix"
        /><span v-if="formDataTrajectory.discount_value" style='color: #c50909;'><b>{{ formDataTrajectory.discount_type == '1' ? parseInt(formDataTrajectory.price0_2) * (1 - (parseInt(formDataTrajectory.discount_value) / 100)) : (parseInt(formDataTrajectory.price0_2) - parseInt(formDataTrajectory.discount_value)) }}</b>€</span>
        <br />
        {{
          formDataTrajectory.depart
            ? formDataTrajectory.depart.split(",")[0] + " - "
            : "N - "
        }}
        {{
          formDataTrajectory.layover3
            ? formDataTrajectory.layover3.split(",")[0] + " : "
            : "N : "
        }}
        <input
          type="text"
          v-model="formDataTrajectory.price0_3"
          placeholder="Prix"
        /><span v-if="formDataTrajectory.discount_value" style='color: #c50909;'><b>{{ formDataTrajectory.discount_type == '1' ? parseInt(formDataTrajectory.price0_3) * (1 - (parseInt(formDataTrajectory.discount_value) / 100)) : (parseInt(formDataTrajectory.price0_3) - parseInt(formDataTrajectory.discount_value)) }}</b>€</span>
        <br />
        {{
          formDataTrajectory.layover1
            ? formDataTrajectory.layover1.split(",")[0] + " - "
            : "N - "
        }}
        {{
          formDataTrajectory.layover2
            ? formDataTrajectory.layover2.split(",")[0] + " : "
            : "N : "
        }}
        <input
          type="text"
          v-model="formDataTrajectory.price1_2"
          placeholder="Prix"
        /><span v-if="formDataTrajectory.discount_value" style='color: #c50909;'><b>{{ formDataTrajectory.discount_type == '1' ? parseInt(formDataTrajectory.price1_2) * (1 - (parseInt(formDataTrajectory.discount_value) / 100)) : (parseInt(formDataTrajectory.price1_2) - parseInt(formDataTrajectory.discount_value)) }}</b>€</span>
        <br />
        {{
          formDataTrajectory.layover1
            ? formDataTrajectory.layover1.split(",")[0] + " - "
            : "N - "
        }}
        {{
          formDataTrajectory.layover3
            ? formDataTrajectory.layover3.split(",")[0] + " : "
            : "N : "
        }}
        <input
          type="text"
          v-model="formDataTrajectory.price1_3"
          placeholder="Prix"
        /><span v-if="formDataTrajectory.discount_value" style='color: #c50909;'><b>{{ formDataTrajectory.discount_type == '1' ? parseInt(formDataTrajectory.price1_3) * (1 - (parseInt(formDataTrajectory.discount_value) / 100)) : (parseInt(formDataTrajectory.price1_3) - parseInt(formDataTrajectory.discount_value)) }}</b>€</span>
        <br />
        {{
          formDataTrajectory.layover1
            ? formDataTrajectory.layover1.split(",")[0] + " - "
            : "N - "
        }}
        {{
          formDataTrajectory.arrival
            ? formDataTrajectory.arrival.split(",")[0] + " : "
            : "N : "
        }}
        <input
          type="text"
          v-model="formDataTrajectory.price1_4"
          placeholder="Prix"
        /><span v-if="formDataTrajectory.discount_value" style='color: #c50909;'><b>{{ formDataTrajectory.discount_type == '1' ? parseInt(formDataTrajectory.price1_4) * (1 - (parseInt(formDataTrajectory.discount_value) / 100)) : (parseInt(formDataTrajectory.price1_4) - parseInt(formDataTrajectory.discount_value)) }}</b>€</span>
        <br />
        {{
          formDataTrajectory.layover2
            ? formDataTrajectory.layover2.split(",")[0] + " - "
            : "N - "
        }}
        {{
          formDataTrajectory.layover3
            ? formDataTrajectory.layover3.split(",")[0] + " : "
            : "N : "
        }}
        <input
          type="text"
          v-model="formDataTrajectory.price2_3"
          placeholder="Prix"
        /><span v-if="formDataTrajectory.discount_value" style='color: #c50909;'><b>{{ formDataTrajectory.discount_type == '1' ? parseInt(formDataTrajectory.price2_3) * (1 - (parseInt(formDataTrajectory.discount_value) / 100)) : (parseInt(formDataTrajectory.price2_3) - parseInt(formDataTrajectory.discount_value)) }}</b>€</span>
        <br />
        {{
          formDataTrajectory.layover2
            ? formDataTrajectory.layover2.split(",")[0] + " - "
            : "N - "
        }}
        {{
          formDataTrajectory.arrival
            ? formDataTrajectory.arrival.split(",")[0] + " : "
            : "N : "
        }}
        <input
          type="text"
          v-model="formDataTrajectory.price2_4"
          placeholder="Prix"
        /><span v-if="formDataTrajectory.discount_value" style='color: #c50909;'><b>{{ formDataTrajectory.discount_type == '1' ? parseInt(formDataTrajectory.price2_4) * (1 - (parseInt(formDataTrajectory.discount_value) / 100)) : (parseInt(formDataTrajectory.price2_4) - parseInt(formDataTrajectory.discount_value)) }}</b>€</span>
        <br />
        {{
          formDataTrajectory.layover3
            ? formDataTrajectory.layover3.split(",")[0] + " - "
            : "N - "
        }}
        {{
          formDataTrajectory.arrival
            ? formDataTrajectory.arrival.split(",")[0] + " : "
            : "N : "
        }}
        <input
          type="text"
          v-model="formDataTrajectory.price3_4"
          placeholder="Prix"
        /><span v-if="formDataTrajectory.discount_value" style='color: #c50909;'><b>{{ formDataTrajectory.discount_type == '1' ? parseInt(formDataTrajectory.price3_4) * (1 - (parseInt(formDataTrajectory.discount_value) / 100)) : (parseInt(formDataTrajectory.price3_4) - parseInt(formDataTrajectory.discount_value)) }}</b>€</span>
        <br />
        <br />
        Nombre de moto réservé/max
        <input
          type="number"
          v-model="formDataTrajectory.moto_reserved"
          placeholder="Moto reservé"
        />
        /
        <input
          type="number"
          v-model="formDataTrajectory.moto_max"
          placeholder="Moto max"
        />
        <br />
        Nombre de voyageur réservé/max
        <input
          type="number"
          v-model="formDataTrajectory.passenger_reserved"
          placeholder="Voyageur reservé"
        />
        /
        <input
          type="number"
          v-model="formDataTrajectory.passenger_max"
          placeholder="Voyageur max"
        />
        <br />
        <br />
        <input
          type="number"
          v-model="formDataTrajectory.discount_value"
          placeholder="Combien de réduction"
        />
        <select v-model="formDataTrajectory.discount_type">
          <option value="1">%</option>
          <option value="2">€</option></select
        >de réduction
        <br />
        <br />
        Tarif groupe:
        <input
          type="number"
          v-model="formDataTrajectory.discount_group_value"
          placeholder="Combien de réduction"
        />% pour ≥
        <input type="number" v-model="formDataTrajectory.discount_group_moto" />
        moto
        <br />
        <br />
        <div class="editor-con">
          Economie<br />
          <QuillEditor
            :options="options"
            v-model:content="formDataTrajectory.eco"
            contentType="html"
          />
        </div>
        <br />
        <button type="submit" class="btn-blue" @click="addTrajectorySend()">
          Ajouter
        </button>
      </form>
    </div>
    </div>
    <div v-else-if="currentTab === 'news'">
      <el-table v-if="news.length > 0" :data="news" stripe>
        <el-table-column type="expand">
          <template #default="props">
            <div class="tr-edit">
              <img
                v-if="props.row.cover"
                :src="`${config.API_BASE_URL}/assets/images/news/${props.row.cover}`"
                alt=""
              />
              <video
                v-if="props.row.video"
                :src="`${config.API_BASE_URL}/assets/videos/news/${props.row.video}`"
                controls="controls"
                autoplay
                mueted
              ></video>
              <br /><br />
              Titre(*):
              <input
                type="text"
                v-model="
                  tmpNews[news.findIndex((item) => item.id === props.row.id)]
                    .title
                "
                placeholder="Titre"
              />
              <br /><br />
              <div class="editor-con">
                <QuillEditor
                  :options="options"
                  v-model:content="
                    tmpNews[news.findIndex((item) => item.id === props.row.id)]
                      .html
                  "
                  contentType="html"
                />
              </div>
              <br />
              Cover:
              <input
                type="file"
                @change="(event) => handleImageUploadEdit(props.row, event)"
                accept="image/*"
              />
              <br /><br />
              Vidéo:
              <input
                type="file"
                @change="(event) => handleVideoUploadEdit(props.row, event)"
                accept="video/*"
              />
              <br /><br />
              <div class="flex space-between">
                <button class="btn-blue" @click="confirmDelNews(props.row)">
                  Supprimer
                </button>
                <button class="btn-blue" @click="editNews(props.row)">
                  Modifier
                </button>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Publication">
          <template #default="{ row }">
            {{ formattedDate(tmpNews[news.findIndex((item) => item.id === row.id)]
                    .date_publication) }}
          </template>
        </el-table-column>
        <el-table-column label="Titre" prop="title" />
        <el-table-column label="Dernière modification">
          <template #default="{ row }">
            {{ formattedDate(tmpNews[news.findIndex((item) => item.id === row.id)]
                    .date_lastedit) }}
          </template>
        </el-table-column>
      </el-table>
      <hr />
      <div class="news-add">
        <b>Ajouter un article</b><br /><br />
        Titre(*):
        <input type="text" v-model="formDataNews.title" placeholder="Titre" />
        <br /><br />
        <div class="editor-con">
          <QuillEditor
            :options="options"
            v-model:content="formDataNews.html"
            contentType="html"
          />
        </div>
        <br />
        Cover:
        <input type="file" @change="handleImageUpload" accept="image/*" />
        <br /><br />
        Vidéo:
        <input type="file" @change="handleVideoUpload" accept="video/*" />
        <br /><br />
        <button class="btn-blue" @click="addNews()">Ajouter</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Autocomplete from "@/components/Autocomplete.vue";
import config from "@/config";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { ElNotification } from 'element-plus'
//import { ref } from 'vue';
export default {
  name: "AdminSpace",
  components: {
    QuillEditor,
    Autocomplete,
  },
  data() {
    return {
      config: config,
      departEdit: null,
      arrivalEdit: null,
      layover1Edit: null,
      layover2Edit: null,
      layover3Edit: null,
      currentTab: "trajectories",
      formDataNews: {
        title: "",
        html: "",
        cover: "",
        video: "",
        fileCover: null,
        fileVideo: null,
      },
      formDataEvent: {
        name: "",
        date_start: "",
        date_end: '',
        img: '',
        link: '',
        details: '',
        location: ''
      },
      formDataTrajectory: {
        eco: "",
        depart: "",
        arrival: "",
        layover1: "",
        layover2: "",
        layover3: "",
        address_depart: "",
        address_arrival: "",
        address_layover1: "",
        address_layover2: "",
        address_layover3: "",
        price0_1: null,
        price0_2: null,
        price0_3: null,
        price0_4: null,
        price1_2: null,
        price1_3: null,
        price1_4: null,
        price2_3: null,
        price2_4: null,
        price3_4: null,
        moto_max: 6,
        moto_reserved: 0,
        passenger_max: 7,
        passenger_reserved: 0,
        time0: "",
        time1: "",
        time2: "",
        time3: "",
        time4: "",
        discount_type: "1",
        discount_value: null,
        discount_group_value: null,
        discount_group_moto: 0,
        completed: "",
      },
      //News editor
      options: {
        modules: {
          toolbar: {
            container: [
              [{ header: 1 }, { header: 2 }, { font: [] }],
              [{ size: ["small", false, "large", "huge"] }],
              ["bold", "italic", "underline", "strike"],
              [{ color: [] }, { background: [] }],
              [{ align: [] }],
              ["link"],
              ["clean"],
            ],
          },
        },
      },
      news: [],
      tmpNews: [],
      trajectories: [],
      tmpTrajectories: [],
      events: [],
      tmpEvents: [],
      refreshAutocomplete: 0,
    };
  },
  created(){
    document.title = 'Admin - Symone';
  },
  watch: {
    formDataTrajectory: function (val) {
      console.log(val.address_depart);
    },
  },
  mounted() {
    this.getNews();
    this.getTrajectories();
    this.getEvents();
  },
  methods: {

    async getEvents() {
      try {
        let response = await axios.get(`${config.API_BASE_URL}/event/get/all`);
        this.events = JSON.parse(JSON.stringify(response.data));
        this.tmpEvents = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error("Error events/get:", error);
      }
    },

    async getNews() {
      try {
        let response = await axios.get(`${config.API_BASE_URL}/news/get/all`);
        this.news = JSON.parse(JSON.stringify(response.data));
        this.tmpNews = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error("Error news/get:", error);
      }
    },

    async getTrajectories() {
      try {
        let response = await axios.get(
          `${config.API_BASE_URL}/trajectory/get/all`
        );
        this.trajectories = JSON.parse(JSON.stringify(response.data));
        this.tmpTrajectories = JSON.parse(JSON.stringify(response.data));
        console.log(1234, this.trajectories);
      } catch (error) {
        console.error("Error trajectories/get:", error);
      }
    },

    async handleImageUpload(event) {
      this.formDataNews.fileCover = event.target.files[0];
    },
    async handleVideoUpload(event) {
      this.formDataNews.fileVideo = event.target.files[0];
    },

    async handleImageUploadEdit(props, event) {
      this.tmpNews[this.news.indexOf(props)]["fileCover"] =
        event.target.files[0];
    },
    async handleVideoUploadEdit(props, event) {
      this.tmpNews[this.news.indexOf(props)]["fileVideo"] =
        event.target.files[0];
    },

    async addNews() {
      // Save HTML content
      const htmlContent = this.formDataNews.html;

      // Upload image
      let imageName = "";
      if (this.formDataNews.fileCover) {
        imageName = await this.uploadFile(
          this.formDataNews.fileCover,
          "images"
        );
      }

      // Upload video
      let videoName = "";
      if (this.formDataNews.fileVideo) {
        videoName = await this.uploadFile(
          this.formDataNews.fileVideo,
          "videos"
        );
      }

      let newsTitle = this.formDataNews.title;

      // Send data to backend for saving
      await this.addNewsSend({ htmlContent, imageName, videoName, newsTitle });
    },

    async editNews(row) {
      // Save HTML content
      const htmlContent = this.tmpNews[this.news.indexOf(row)].html;

      // Upload image
      let imageName = row.cover;
      if (this.tmpNews[this.news.indexOf(row)].fileCover) {
        imageName = await this.uploadFileEdit(
          this.tmpNews[this.news.indexOf(row)].fileCover,
          "images",
          row.cover
        );
      }

      // Upload video
      let videoName = row.video;
      if (this.tmpNews[this.news.indexOf(row)].fileVideo) {
        videoName = await this.uploadFileEdit(
          this.tmpNews[this.news.indexOf(row)].fileVideo,
          "videos",
          row.video
        );
      }
      let newsId = row.id;
      let newsTitle = this.tmpNews[this.news.indexOf(row)].title;

      // Send data to backend for saving
      await this.editNewsSend({
        htmlContent,
        imageName,
        videoName,
        newsTitle,
        newsId,
      });
    },
    async uploadFile(file, fileType) {
      const formData = new FormData();
      formData.append("file", file);
      const response = await fetch(
        `${config.API_BASE_URL}/news/upload/${fileType}`,
        {
          method: "POST",
          body: formData,
        }
      );
      const responseData = await response.json();
      return responseData.fileName;
    },
    async uploadFileEdit(file, fileType, oldMedia = null) {
      const formData = new FormData();
      formData.append("file", file);
      if (oldMedia) formData.append("oldMedia", oldMedia);
      const response = await fetch(
        `${config.API_BASE_URL}/news/upload/${fileType}`,
        {
          method: "POST",
          body: formData,
        }
      );
      const responseData = await response.json();
      return responseData.fileName;
    },
    async addNewsSend(data) {
      try {
        const response = await fetch(`${config.API_BASE_URL}/news/add`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData); // handle response as needed
        this.refreshNews();
        this.formDataNews = {
          title: "",
          html: "",
          cover: "",
          video: "",
          fileCover: null,
          fileVideo: null,
        }
      } catch (error) {
        console.error("Error News/add :", error);
      }
    },
    async addEventSend() {
      try {
        const response = await axios.post(
          `${config.API_BASE_URL}/event/add`,
          {
            data: this.formDataEvent,
          }
        );
        const responseData = await response;
        console.log(responseData); // handle response as needed
        this.refreshEvents();
        this.formDataEvent = {
          name: "",
          date_start: "",
          date_end: '',
          img: '',
          link: '',
          details: '',
          location: ''
        }
      } catch (error) {
        console.error("Error Event/add :", error);
      }
    },
    async addTrajectorySend() {
      try {
        this.formDataTrajectory.depart
          ? (this.formDataTrajectory.depart =
              this.formDataTrajectory.depart.split(",")[0].trim() +
              ", " +
              this.formDataTrajectory.depart
                .split(",")[this.formDataTrajectory.depart.split(",").length - 1].trim())
          : "";
        this.formDataTrajectory.arrival
          ? (this.formDataTrajectory.arrival =
              this.formDataTrajectory.arrival.split(",")[0].trim() +
              ", " +
              this.formDataTrajectory.arrival
                .split(",")[this.formDataTrajectory.arrival.split(",").length - 1].trim())
          : "";
        this.formDataTrajectory.layover1
          ? (this.formDataTrajectory.layover1 =
              this.formDataTrajectory.layover1.split(",")[0].trim() +
              ", " +
              this.formDataTrajectory.layover1
                .split(",")[this.formDataTrajectory.layover1.split(",").length - 1].trim())
          : "";
        this.formDataTrajectory.layover2
          ? (this.formDataTrajectory.layover2 =
              this.formDataTrajectory.layover2.split(",")[0].trim() +
              ", " +
              this.formDataTrajectory.layover2
                .split(",")[this.formDataTrajectory.layover2.split(",").length - 1].trim())
          : "";
        this.formDataTrajectory.layover3
          ? (this.formDataTrajectory.layover3 =
              this.formDataTrajectory.layover3.split(",")[0].trim() +
              ", " +
              this.formDataTrajectory.layover3
                .split(",")[this.formDataTrajectory.layover3.split(",").length - 1].trim())
          : "";

        const response = await axios.post(
          `${config.API_BASE_URL}/trajectory/add`,
          {
            data: this.formDataTrajectory,
          }
        );
        const responseData = await response;
        console.log(responseData); // handle response as needed
        this.refreshTrajectories();
      } catch (error) {
        console.error("Error Trajectories/add :", error);
      }
    },
    async editNewsSend(data) {
      try {
        const response = await fetch(
          `${config.API_BASE_URL}/news/edit/${data.newsId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log(responseData);
        this.refreshNews();
      } catch (error) {
        console.error("Error News/edit :", error);
      }
    },
    async editEventSend(row) {
      try {
        const response = await axios.post(
          `${config.API_BASE_URL}/event/edit/${row.id}`,
          {
            data: this.tmpEvents[
              this.events.findIndex((item) => item.id === row.id)
            ],
          }
        );
        const responseData = await response;
        console.log(responseData);
        this.refreshEvents();
      } catch (error) {
        console.error("Error Event/edit :", error);
      }
    },
    async editTrajectorySend(row) {
      try {
        this.tmpTrajectories[
          this.trajectories.findIndex((item) => item.id === row.id)
        ].depart
          ? (this.tmpTrajectories[
              this.trajectories.findIndex((item) => item.id === row.id)
            ].depart =
              this.tmpTrajectories[
                this.trajectories.findIndex((item) => item.id === row.id)
              ].depart
                .split(",")[0]
                .trim() +
              ", " +
              this.tmpTrajectories[
                this.trajectories.findIndex((item) => item.id === row.id)
              ].depart
                .split(",")[
                  this.tmpTrajectories[
                    this.trajectories.findIndex((item) => item.id === row.id)
                  ].depart.split(",").length - 1
                ].trim())
          : "";
        this.tmpTrajectories[
          this.trajectories.findIndex((item) => item.id === row.id)
        ].arrival
          ? (this.tmpTrajectories[
              this.trajectories.findIndex((item) => item.id === row.id)
            ].arrival =
              this.tmpTrajectories[
                this.trajectories.findIndex((item) => item.id === row.id)
              ].arrival
                .split(",")[0]
                .trim() +
              ", " +
              this.tmpTrajectories[
                this.trajectories.findIndex((item) => item.id === row.id)
              ].arrival
                .split(",")[
                  this.tmpTrajectories[
                    this.trajectories.findIndex((item) => item.id === row.id)
                  ].arrival.split(",").length - 1
                ].trim())
          : "";
        this.tmpTrajectories[
          this.trajectories.findIndex((item) => item.id === row.id)
        ].layover1
          ? (this.tmpTrajectories[
              this.trajectories.findIndex((item) => item.id === row.id)
            ].layover1 =
              this.tmpTrajectories[
                this.trajectories.findIndex((item) => item.id === row.id)
              ].layover1
                .split(",")[0]
                .trim() +
              ", " +
              this.tmpTrajectories[
                this.trajectories.findIndex((item) => item.id === row.id)
              ].layover1
                .split(",")[
                  this.tmpTrajectories[
                    this.trajectories.findIndex((item) => item.id === row.id)
                  ].layover1.split(",").length - 1
                ].trim())
          : "";
        this.tmpTrajectories[
          this.trajectories.findIndex((item) => item.id === row.id)
        ].layover2
          ? (this.tmpTrajectories[
              this.trajectories.findIndex((item) => item.id === row.id)
            ].layover2 =
              this.tmpTrajectories[
                this.trajectories.findIndex((item) => item.id === row.id)
              ].layover2
                .split(",")[0]
                .trim() +
              ", " +
              this.tmpTrajectories[
                this.trajectories.findIndex((item) => item.id === row.id)
              ].layover2
                .split(",")[
                  this.tmpTrajectories[
                    this.trajectories.findIndex((item) => item.id === row.id)
                  ].layover2.split(",").length - 1
                ].trim())
          : "";
        this.tmpTrajectories[
          this.trajectories.findIndex((item) => item.id === row.id)
        ].layover3
          ? (this.tmpTrajectories[
              this.trajectories.findIndex((item) => item.id === row.id)
            ].layover3 =
              this.tmpTrajectories[
                this.trajectories.findIndex((item) => item.id === row.id)
              ].layover3
                .split(",")[0]
                .trim() +
              ", " +
              this.tmpTrajectories[
                this.trajectories.findIndex((item) => item.id === row.id)
              ].layover3
                .split(",")[
                  this.tmpTrajectories[
                    this.trajectories.findIndex((item) => item.id === row.id)
                  ].layover3.split(",").length - 1
                ].trim())
          : "";

        const response = await axios.post(
          `${config.API_BASE_URL}/trajectory/edit/${row.id}`,
          {
            data: this.tmpTrajectories[
              this.trajectories.findIndex((item) => item.id === row.id)
            ],
          }
        );
        const responseData = await response;
        console.log(responseData);
        this.refreshTrajectories();
      } catch (error) {
        console.error("Error Trajectory/edit :", error);
      }
    },
    async delNews(id) {
      try {
        const response = await fetch(`${config.API_BASE_URL}/news/del/${id}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const responseData = await response.json();
        console.log(responseData);

        this.refreshNews();
      } catch (error) {
        console.error("Error News/del :", error);
      }
    },
    async delTrajectory(id) {
      try {
        const response = await fetch(
          `${config.API_BASE_URL}/trajectory/del/${id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = await response.json();
        console.log(responseData);

        this.refreshTrajectories();
      } catch (error) {
        console.error("Error Trajectory/del :", error);
      }
    },
    async delEvent(id) {
      try {
        const response = await fetch(
          `${config.API_BASE_URL}/event/del/${id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = await response.json();
        console.log(responseData);

        this.refreshEvents();
      } catch (error) {
        console.error("Error Event/del :", error);
      }
    },
    confirmDelNews(row) {
      this.$confirm(
        `Confirmez-vous la suppression du article "${row.title}"?`,
        "Attention",
        {
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
          type: "warning",
        }
      )
        .then(() => {
          this.delNews(row.id);
        })
        .catch(() => {});
    },
    confirmDelEvent(row) {
      this.$confirm(
        `Confirmez-vous la suppression du event "${row.name}"?`,
        "Attention",
        {
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
          type: "warning",
        }
      )
        .then(() => {
          this.delEvent(row.id);
        })
        .catch(() => {});
    },
    confirmDelTrajectory(row) {
      this.$confirm(
        `Confirmez-vous la suppression du trajet ${row.depart.split(",")[0]}${
          row.layover1 ? " → " + row.layover1.split(",")[0] : ""
        }${row.layover2 ? " → " + row.layover2.split(",")[0] : ""}${
          row.layover3 ? " → " + row.layover3.split(",")[0] : ""
        } → ${row.arrival.split(",")[0]} ?`,
        "Attention",
        {
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
          type: "warning",
        }
      )
        .then(() => {
          this.delTrajectory(row.id);
        })
        .catch(() => {});
    },
    duplicateTrajectory(trajectory){
      this.formDataTrajectory = JSON.parse(JSON.stringify(trajectory));
      this.refreshAutocomplete++;
      document.getElementById('formTrajectory').scrollIntoView({ behavior: 'smooth', block: 'start'});
    },
    formattedDate(date) {
      console.log(888,date)
      date = String(date);
      if (date[4] != "-") {
        return date.slice(6, 8).padStart(2, "0") + "-" + date.slice(4, 6).padStart(2, "0") + "-" + date.slice(0, 4);
      } else {
        return date.slice(8, 10).padStart(2, "0") + "-" + date.slice(5, 7).padStart(2, "0") + "-" + date.slice(0, 4);
      }
    },
    refreshNews() {
      ElNotification({
        title: 'Success',
        message: 'Opération terminée',
        type: 'success',
        position: 'bottom-right',
        duration: 0
      })
      setTimeout(() => {
        this.getNews();
      }, 2000);
    },
    refreshEvents() {
      ElNotification({
        title: 'Success',
        message: 'Opération terminée',
        type: 'success',
        position: 'bottom-right',
        duration: 0
      })
      setTimeout(() => {
        this.getEvents();
      }, 2000);
    },
    refreshTrajectories() {
      ElNotification({
        title: 'Success',
        message: 'Opération terminée',
        type: 'success',
        position: 'bottom-right',
        duration: 0
      })
      setTimeout(() => {
        location.reload();
      }, 2000);
    },
    switchTab(tab) {
      this.currentTab = tab;
    },
    formatDateToInputDateValue(yyyyMMdd) {
      if (yyyyMMdd[4] != "-") {
        const year = yyyyMMdd.slice(0, 4);
        const month = yyyyMMdd.slice(4, 6).padStart(2, "0");
        const day = yyyyMMdd.slice(6, 8).padStart(2, "0");

        return `${year}-${month}-${day}`;
      } else {
        return yyyyMMdd;
      }
    },
    formatDate(dateString) {
      const year = dateString.slice(0, 4);
      const month = dateString.slice(4, 6).padStart(2, "0");
      const day = dateString.slice(6, 8).padStart(2, "0");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>
<style lang="scss">
.autocomplete {
  max-width: 400px;
  width: 100%;
}
.rose {
  color: #e6007e;
}
.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.space-between {
  justify-content: space-between;
}
.btn-blue {
  background-color: #000047;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
}
.news-add {
  padding: 50px;
  background: whitesmoke;
}
.tr-edit {
  padding: 50px;
  img,
  video {
    max-height: 180px;
  }
}
.menu-admin {
  display: inline;
  color: white;
  font-size: 37px;
  font-weight: 300;
  margin: 0 20px;
  cursor: pointer;
}

.menu-admin.active {
  color: #45bbbf; /* 被点击的颜色 */
  font-weight: 400;
}

.menu-admin:not(.active) {
  color: white; /* 没被点击的颜色 */
  font-weight: 300;
}
</style>

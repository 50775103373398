<template>
    <div style="width: -webkit-fill-available;">
        <div class="nav">
            <a href="/" target="_self"
            ><img
                src="../assets/images/ui/logo.png"
                style="max-width: 250px"
            /></a>
        </div>
        <div class="container">
          <div class="title">Conditions Générales de Vente de transport routier de voyageurs SYMONE</div>
          <div class='w-1'>
          <div class="txt">
            
            <div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-e0bf7e7 e-flex e-con-boxed e-con" data-id="e0bf7e7" data-element_type="container" data-settings="{&quot;content_width&quot;:&quot;boxed&quot;}">
					<div class="e-con-inner">
				<div class="elementor-element elementor-element-84e40a5 elementor-widget elementor-widget-text-editor" data-id="84e40a5" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>(moins de 10 places)</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p><strong>Version 1.005 applicable à compter du 23 décembre 2023</strong></p>						</div>
				</div><br>
				<div class="elementor-element elementor-element-ba9cb75 elementor-widget elementor-widget-heading" data-id="ba9cb75" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
			<h2 class="elementor-heading-title elementor-size-default">Préambule</h2>		</div>
				</div>
					</div>
				</div>
				<div class="elementor-element elementor-element-9bfad80 elementor-widget elementor-widget-text-editor" data-id="9bfad80" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p><span style="color: var( --e-global-color-text ); font-family: var( --e-global-typography-text-font-family ), Sans-serif; font-weight: var( --e-global-typography-text-font-weight ); font-size: 1rem;">La société SYMONE, société par actions simplifiée, au capital de 61&nbsp;208€, immatriculée au registre de commerce et des sociétés de DIJON sous le numéro 893 594&nbsp;465 dont le siège social est établi au 75 B avenue du drapeau, 21&nbsp;000 DIJON (France) a pour activité secondaire le transport routier occasionnel de voyageurs au moyen de véhicule léger de moins de 10 places.&nbsp;</span></p>
<p>SYMONE offre des services de transport nationaux occasionnels pour transporter en même temps les motard(e)s avec leur véhicule au moyen d'une remorque et d'un véhicule léger de moins de 10 places.</p>
<p>Aucune autre prestation complémentaire du type vente de boissons, alimentations, services connexion internet ne sera proposée à bord des véhicules lors des trajets.&nbsp;&nbsp;</p>						</div>
				</div>
				<div class="elementor-element elementor-element-90ae5fe elementor-widget elementor-widget-heading" data-id="90ae5fe" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">1. Définitions&nbsp;</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-d370f72 elementor-widget elementor-widget-text-editor" data-id="d370f72" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p><strong>Billet</strong> : désigne tout document valide donnant droit au transport ou son équivalent sous toute autre forme autre qu'imprimé, y compris électronique, envoyé ou autorisé par l'entreprise. Le Billet sera nominatif, exclusif et valable uniquement pour le transport indiqué sur celui-ci et pour la personne mentionnée comme le Client ; par conséquent, il ne pourra être en aucun cas transférable à des tierces personnes.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p><strong>Client</strong> : désigne la personne physique identifiée sur le Billet et détentrice du billet pour la prestation convenue.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p><strong>Commande </strong>: qui désigne les commandes passées par le client par le biais du Site internet, par le mail à « <a href="mailto:reservation@symone.fr">reservation@symone.fr</a>&nbsp;» OU «&nbsp;<a href="mailto:contact@symone.fr">contact@symone.fr</a> &nbsp;»</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p><strong>Conditions Générales de Vente</strong> (CGV) : désigne les présentes Conditions Générales telles que détaillées au présent document.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p><strong>Partenaire</strong>&nbsp;: désigne une entreprise de la mobilité ou tout autre secteur avec laquelle SYMONE a conclu un accord de partenariat.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p><strong>Partie(s)</strong> : désigne SYMONE et/ou le Client.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p><strong>Prestation&nbsp;</strong>: désigne la prestation principale de transport (du client et éventuellement de son véhicule) exécutée par SYMONE au titre du contrat souscrit entre les parties.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p><strong>Site internet</strong>&nbsp;: désigne le site internet de l'entreprise SYMONE.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p><strong>Site Partenaire</strong> : désigne tout site tiers qui redirige vers le Site internet pour la réservation de Billets.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p><strong>SYMONE</strong>&nbsp;: désigne la société mentionnée dans le Préambule.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p><strong>Trajet&nbsp;</strong>: désigne l'itinéraire choisi par le client.</p>						</div>
				</div>
				<div class="elementor-element elementor-element-3a29108 elementor-widget elementor-widget-heading" data-id="3a29108" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">2. Objet</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-4360700 elementor-widget elementor-widget-text-editor" data-id="4360700" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>Le présent document contient les CGV de la Prestation SYMONE.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Les CGV ont vocation à régir, sans limitation, l'ensemble de la Prestation proposée à la vente par SYMONE via le Site internet ou via la redirection assurée par un Site Partenaire.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Les CGV définissent les rapports contractuels entre SYMONE et le Client.</p>						</div>
				</div>
				<div class="elementor-element elementor-element-2aff810 elementor-widget elementor-widget-heading" data-id="2aff810" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">3. Acceptation des Conditions Générales de Vente</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-c6efb3c elementor-widget elementor-widget-text-editor" data-id="c6efb3c" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>Les CGV doivent faire l'objet d'une acceptation explicite, sans restriction ni réserve du Client sur l'intégralité des termes et conditions de vente avant que celui-ci ne s'engage sur une commande.&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Un processus d'acceptation sera mis en place pour les Commandes passées sur le Site internet avec une acceptation des CGV applicables au moment du paiement du Billet et avant de finaliser son acte d'achat.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>En cas d'achat du Billet par une tierce personne, l'acheteur sera chargé par conséquent de remettre au Client une copie des présentes Conditions Générales.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Les présentes Conditions Générales sont accessibles sur le Site et seront mises à disposition du Client lors de sa Réservation.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>En cas de réserve du Client, de quelque nature ou importance que ce soit au sujet des CGV, le Client, est invité à renoncer à poursuivre le processus de réservation et d'achat de la prestation. À défaut, le Client est irrévocablement réputé accepter les CGV du seul fait de la poursuite du processus d'achat.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Les CGV sont accessibles en ligne sur le site internet ou sur demande.&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Les CGV pouvant faire l'objet de modifications, les conditions applicables sont celles en vigueur à la date d'achat de la prestation.</p>						</div>
				</div>
				<div class="elementor-element elementor-element-6bd1c43 elementor-widget elementor-widget-heading" data-id="6bd1c43" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">4. Conclusion du Contrat de Transport&nbsp;</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-33f18f3 elementor-widget elementor-widget-text-editor" data-id="33f18f3" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>Le contrat conclu entre SYMONE et le Client est un contrat de transport dit :</p>
<ul>
<li style="list-style-type: none;">
<ul>
<li>consensuel&nbsp;: résultant de la seule volonté entre le client et le transporteur,</li>
</ul>
</li>
</ul>
<p><!-- /wp:list-item --><!-- wp:list-item --></p>
<ul>
<li style="list-style-type: none;">
<ul>
<li>bilatéral&nbsp;: les 2 parties s'engagent réciproquement,</li>
</ul>
</li>
</ul>
<p><!-- /wp:list-item --><!-- wp:list-item --></p>
<ul>
<li style="list-style-type: none;">
<ul>
<li>à titre onéreux pour chacune des parties,</li>
</ul>
</li>
</ul>
<p><!-- /wp:list-item --><!-- wp:list-item --></p>
<ul>
<li style="list-style-type: none;">
<ul>
<li>commutatif&nbsp;: prévoyant des obligations équivalentes et dont le contenu est connu à l'avance.</li>
</ul>
</li>
</ul>
<p><!-- /wp:list-item --></p>
<p><!-- /wp:list --><!-- wp:paragraph --></p>
<p>Conformément à la réglementation depuis le 1<sup>er</sup> Octobre 2013, l'entreprise SYMONE s'engage à informer le bénéficiaire de la prestation de transport de la quantité de CO2 émise lors de son trajet.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Dans l'hypothèse où un tiers réalise l'achat pour le Client, ce tiers déclare agir au nom et pour le compte du Client, bénéficiaire de la Prestation commandée par ses soins, dont il reconnaît être le mandataire au sens de la loi applicable, (ci-après désigné le « Mandataire »).</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Le Contrat est définitivement conclu à partir de la validation de la réservation après acceptation des CGV et complet paiement du prix de la prestation par le Client ou par son éventuel Mandataire.</p>						</div>
				</div>
				<div class="elementor-element elementor-element-bee84bc elementor-widget elementor-widget-heading" data-id="bee84bc" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">5. Modalités de passation de la Commande&nbsp;</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-cb5d76a elementor-widget elementor-widget-text-editor" data-id="cb5d76a" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>La commande est l'opération par laquelle le Client ou son éventuel mandataire définit son besoin de trajet quel que soit le moyen employé proposé par l'entreprise&nbsp;; puis se finalise par la validation et confirmation de l'entreprise SYMONE auprès du Client.&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Le Client reconnaît et accepte que cette opération se fait sous son entière et seule responsabilité ou celle de son éventuel Mandataire</p>						</div>
				</div>
				<div class="elementor-element elementor-element-b5f012e elementor-widget elementor-widget-heading" data-id="b5f012e" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">6. Fourniture des Informations Personnelles&nbsp;</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-851f8a8 elementor-widget elementor-widget-text-editor" data-id="851f8a8" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>Tout achat de prestation nécessite la collecte d'informations sur le Client par l'entreprise SYMONE pouvant éventuellement être qualifiées de données à caractère personnel ainsi que des données relatives au paiement (RIB, SEPA).&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>La Société SYMONE est le responsable du traitement au sens du Règlement (UE) 2016/679 dit Règlement Général pour la Protection des Données ou RGPD, pour la collecte et le traitement des données à caractère personnel effectués sur le site internet. Vous disposez d'un droit d'accès, de rectification ou d'effacement des données à caractère personnel vous concernant, et le cas échéant, d'un droit à la portabilité de vos données.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Vous pouvez demander la limitation ou vous opposer au traitement de vos données, ou le cas échéant, retirer votre consentement. Vous pouvez exercer ces droits en écrivant à l'adresse suivante : <a href="mailto:contact@symone.fr">contact@symone.fr</a>&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Le Client garantit la véracité et l'exactitude des Informations personnelles fournies par lui ou son éventuel Mandataire lors de la réservation jusqu'à l'achat de la prestation.&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>À cet égard, le Client est invité à veiller à ce que les Informations Personnelles fournies soient valides.&nbsp;</p>						</div>
				</div>
				<div class="elementor-element elementor-element-d9198a9 elementor-widget elementor-widget-heading" data-id="d9198a9" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">7. Paiement&nbsp;&nbsp;</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-585fdb0 elementor-widget elementor-widget-text-editor" data-id="585fdb0" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>Les modes de règlement acceptés par SYMONE sont le virement (le RIB figure sur le devis) et le paiement par carte bleue via un lien mis à disposition du client dans le devis ou un second mail spécifique.</p>						</div>
				</div>
				<div class="elementor-element elementor-element-37083ad elementor-widget elementor-widget-heading" data-id="37083ad" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">8. Validation de la Commande</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-018b644 elementor-widget elementor-widget-text-editor" data-id="018b644" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>Aucune validation de la commande n'intervient avant le paiement effectif et intégral du prix de la prestation validée par le Client ou son éventuel Mandataire.&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>La validation de la commande se matérialise par l'envoi du titre de transport via un courrier électronique à destination du Client (adresse mail client mentionnée lors de la commande) reprenant les détails de celle-ci.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Le Client est invité à être vigilant et vérifier que le courrier électronique de validation avec le titre de transport n'est pas placé directement parmi les courriers indésirables/spam.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>La validation de la commande est définitive. En conséquence, toute modification donnera lieu soit à un échange, soit un avoir pour un autre trajet ou à une annulation dans les conditions de l'Article 11&nbsp; «Conditions de modifications, d'échange et annulation». L'exactitude des Informations Personnelles saisies par le Client ou son éventuel Mandataire est de la responsabilité de ces derniers.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Aucun achat direct à bord du véhicule avant son départ n'est possible.</p>						</div>
				</div>
				<div class="elementor-element elementor-element-182561d elementor-widget elementor-widget-heading" data-id="182561d" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">9. Titre de transport et autres documents</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-e0dbc41 elementor-widget elementor-widget-text-editor" data-id="e0dbc41" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>SYMONE remet donc au Client un titre de transport nominatif en cours de validité constituant la preuve de l'existence du Contrat de Transport entre SYMONE et ledit Client (le « Billet »).&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>La prestation effectuée par SYMONE n'est délivrée qu'au(x) seul(s) Client(s) désigné(s) sur le Billet. De fait SYMONE se réserve le droit de contrôler l'identité du Client présentant son Billet.&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>En cas de perte du Billet ou d'impossibilité pour le Client de le présenter au conducteur, le Client sera invité simplement à fournir la preuve de son identité.&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Il appartient au Client ainsi qu'à son éventuel mandataire de prendre connaissance des formalités sanitaires et administratives normalement requises pour l'exécution du trajet (détention d'un passeport, d'une carte nationale d'identité, d'une carte de séjour, d'un visa, certificat médical, etc…)&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>L'entreprise SYMONE ne pourra être tenue responsable de l'impossibilité pour le Client de prendre le départ à la date indiquée si ces événements résultent du non-respect des formalités par le Client.</p>						</div>
				</div>
				<div class="elementor-element elementor-element-8e572f4 elementor-widget elementor-widget-heading" data-id="8e572f4" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">10. Tarifs</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-b4cff90 elementor-widget elementor-widget-text-editor" data-id="b4cff90" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>Le prix du Billet concerne uniquement le transport depuis la destination de départ jusqu'à la destination d'arrivée, aux aires de chargement et déchargements prévus dans le devis et le billet. Les bagages du Client qui doivent respecter les contraintes de poids et dimensions sont inclus dans le tarif du billet.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Les tarifs sont affichés toutes taxes comprises.</p>						</div>
				</div>
				<div class="elementor-element elementor-element-2539609 elementor-widget elementor-widget-heading" data-id="2539609" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">11. Conditions de modifications, d'échange et annulation&nbsp;&nbsp;</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-c04eae9 elementor-widget elementor-widget-text-editor" data-id="c04eae9" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>SYMONE ne sera nullement responsable en cas de non-utilisation du Billet par le Passager.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Toute demande de modifications, d'échanges, d'annulation ou de remboursement ne peut se faire que par une des 2 adresses mail suivantes&nbsp;: « <a href="mailto:reservation@symone.fr">reservation@symone.fr</a>&nbsp;» OU «&nbsp;<a href="mailto:contact@symone.fr">contact@symone.fr</a> &nbsp;».&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p><br>
<p>A. Par le passager&nbsp;:&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p><br>
<p>-Généralités</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>En application des dispositions de la loi applicable, les contrats portant sur les services de transport de passagers sont exclus du champ d'application des dispositions de loi applicable pour les contrats à distance et hors établissement instituant un droit de rétractation au profit du consommateur.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Par conséquent, la prestation fournie au Client dans le cadre de l'exécution du Contrat de transport peut faire l'objet uniquement de modifications, d'échange ou d'annulation selon les conditions contractuelles prévues ci-après.&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p><br>
<p>-Modifications/échanges</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>La demande de modification est à faire sur l'une des adresses mail suivantes&nbsp;:&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>« <a href="mailto:reservation@symone.fr">reservation@symone.fr</a>&nbsp;» OU «&nbsp;<a href="mailto:contact@symone.fr">contact@symone.fr</a> &nbsp;».&nbsp;&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>La modification ou l'échange au-delà de 30 jours avant la date de départ du trajet aller, sera conditionné aux possibilités que l'entreprise SYMONE pourra fournir et fera l'objet d'un nouveau devis.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p><br>
<p>Dans les 2 cas&nbsp;:</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>L'entreprise SYMONE mettra tout en œuvre pour répondre à votre demande mais ne peut garantir la possibilité de changement/modification.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Si le prix du nouveau Billet est supérieur au prix de celui qui est modifié/échangé, la différence sera supportée par le Client.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Si le prix du nouveau Billet est inférieur au prix de celui qui est modifié/échangé, le Client recevra un bon d'achat du montant de la différence.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>S'il n'est pas possible d'accepter la modification ou l'échange sollicité, se référer aux conditions d'annulation ci-dessous.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p><br>
<p>-Annulation</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Annulation 60 jours (et plus) avant la date de départ du trajet Aller ==> Remboursement à 100% (hors frais de dossiers de 50 euros HT).</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Annulation entre 15 jours et 59 jours inclus avant la date de départ du trajet Aller ==> Remboursement à 50% (hors frais de dossiers de 50 euros HT).</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Annulation à moins de 15 jours avant la date de départ du trajet Aller ==> Pas de remboursement possible. Ces Billets perdront leur validité.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p><br>
<p>B. Par SYMONE&nbsp;:</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>SYMONE s'engage à mettre tout en œuvre pour assurer la prestation dans les meilleures conditions.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>En cas d'annulation d'un trajet par SYMONE en cas de force majeure ou d'autres circonstances, l'entreprise informera par tout moyen le Client des possibilités qui lui seront offertes. Elle s'engage à mettre en œuvre toutes les dispositions pour trouver une solution alternative au Client.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>En tout état de cause, SYMONE pourra proposer au Client le remboursement du prix du Billet.</p>						</div>
				</div>
				<div class="elementor-element elementor-element-1274fc8 elementor-widget elementor-widget-heading" data-id="1274fc8" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">12. Mineurs&nbsp;</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-0b9f394 elementor-widget elementor-widget-text-editor" data-id="0b9f394" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>Pour voyager à bord de nos véhicules légers, les mineurs de moins de 16 ans doivent être accompagnés d'une personne majeure (âgée au moins de 18 ans).</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph {"fontSize":"medium"} --></p>						</div>
				</div>
				<div class="elementor-element elementor-element-57b54f6 elementor-widget elementor-widget-heading" data-id="57b54f6" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">13. Embarquement et refus d'embarquement</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-cbfb0d1 elementor-widget elementor-widget-text-editor" data-id="cbfb0d1" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
							<p><strong><span style="text-decoration: underline;">Le Client</span></strong>&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Sauf indication contraire fournie par SYMONE, le Client monte à bord du véhicule au point d'embarquement indiqué sur le Billet.&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Afin de garantir un embarquement sécurisé et le respect des horaires, les Clients sont invités à se présenter à l'embarquement au plus tard quinze (15) minutes avant l'horaire de départ indiqué sur le Billet. À défaut, SYMONE ne garantit pas le transport du Client présent à l'embarquement moins de quinze (15) minutes avant le départ dans le cas où ce retard engendre ou risque manifestement d'engendrer un risque en termes de sécurité ou de respect des horaires.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Si le Client effectue un voyage <span style="text-decoration: underline;">en provenance de l'étranger</span>, il doit impérativement être muni de :</p>
<ul>
<li>une carte nationale d'identité ou un passeport en cours de validité s'il réside dans l'espace Schengen ;</li>
</ul>
<ul>
<li>un passeport en cours de validité s'il ne réside pas dans l'espace Schengen et se renseigner auprès de l'ambassade ou du consulat concerné sur les visas requis, le cas échéant ;</li>
</ul>
<p><!-- /wp:list-item --></p>
<p><!-- /wp:list --><!-- wp:paragraph --></p>
<p>SYMONE se réserve le droit de refuser le Client à l'embarquement ou de solliciter le débarquement du Client au cours du voyage notamment dans les cas suivants :</p>

<ul>
<li>si le Client n'est pas en possession d'un titre de transport et d'un document d'identité valable&nbsp;;</li>
</ul>

<ul>
<li>s'il n'existe pas de concordance entre le document d'identité fourni par le Client et les informations indiquées sur le titre de transport.</li>
</ul>
<ul>
<li>si le Client ne respecte pas la réglementation applicable ou les obligations découlant des présentes CGV et notamment celles concernant les obligations d'hygiène et sécurité;</li>
</ul>

<ul>
<li>si le Client ne respecte pas les limitations en termes de bagages stipulées dans cet article&nbsp;;</li>
</ul>

<ul>
<li>si le transport ou la poursuite du transport du Client menace manifestement la sécurité.</li>
</ul>
<p><!-- /wp:list-item --></p>
<p><!-- /wp:list --><!-- wp:paragraph --></p>
<p>Pour tous ces cas visés SYMONE n'est, en aucun cas, tenue au remboursement total ou partiel du Billet ou à une quelconque forme de dédommagement.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p><br>
<p><strong><span style="text-decoration: underline;">La moto</span></strong></p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Les opérations d'embarquement et de débarquement sur la remorque devront être effectuées sous l'entière responsabilité du Client conduisant lui-même son véhicule. Il pourra solliciter l'aide du conducteur Symone en cas de besoin, toujours sous la responsabilité du Client.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Une fois la moto embarquée sur la remorque, le conducteur professionnel SYMONE sera en charge de l'arrimage et du désarrimage de la moto sur la remorque. Il s'assure par ailleurs qu'il s'agit bien de la moto précisée lors de la réservation et se réserve le droit de refuser tout véhicule autre que celui stipulé lors de cette réservation. Le client restera à proximité durant ces opérations pour s'assurer du bon déroulement de l'arrimage/désarrimage de sa moto.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>SYMONE n'est pas responsable pour les pertes ou avaries subies par les véhicules pendant les opérations d'embarquement ou de débarquement.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Au moment du débarquement pour récupérer son véhicule, le Client signe une décharge dégageant l'entreprise de toute responsabilité sur l'état du véhicule réceptionné.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Les réclamations pour dommages aux véhicules doivent être notifiées immédiatement au conducteur qui rédigera un rapport d'avaries pour le compte du Client. Le Client devra contresigner ce rapport qui ne constituera aucune acceptation de responsabilité et/ou reconnaissance d'aucune sorte de la part de l'entreprise SYMONE. Les réclamations non accompagnées du rapport d'avaries ne seront pas acceptées ni examinées par l'entreprise SYMONE.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p><br>
<p><strong><span style="text-decoration: underline;">Les bagages</span></strong></p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Lors de la réservation, tous bagages doivent être signalés (dimensions et poids approximatifs) lors de la réservation (bagages à main, sac à dos, topcase, sacoches cavalières, etc).</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>En fonction des contraintes et limites de bagages en raison du poids maximum à tracter, un supplément pourra être demandé lors de la réservation du trajet.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>SYMONE se réserve le droit de refuser les bagages dont le poids, les dimensions ou la nature ne correspondent pas à ce qui avait été convenu avec le Client ; ainsi que ceux que l'entreprise estime préjudiciable à la sécurité du transport.&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Le conducteur professionnel Symone se réserve également le droit de refuser tout bagage supplémentaire non déclaré.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Les bagages doivent impérativement être étiquetés avec le nom, le numéro de téléphone et l'adresse du Client concerné ou a minima son adresse électronique. L'étiquetage des bagages est à la charge du Client et relève de sa seule responsabilité.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Pour des raisons de sécurité et/ou de sûreté et/ou à la demande des autorités, le Client peut être sollicité afin de procéder à un contrôle de son ou de ses bagages. Si le Client refuse de se conformer à une telle demande, SYMONE pourra refuser de le transporter ainsi que son ou ses bagages.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>SYMONE n'est pas responsable du vol, disparition, dommages éventuellement subis par des objets de valeur, argent, documents, manuscrits, gardés dans les bagages. De même SYMONE n'est pas responsable des pertes ou avaries des objets contenus dans la moto</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>La perte ou la détérioration de bagages/moto liée à un accident résultant de l'utilisation du véhicule SYMONE donne lieu à une indemnisation du Client par le transporteur pour tout dommage justifié dont il sera reconnu responsable.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Ci-dessous le tableau de rappel concernant la garantie des bagages “moto” et bagages autres.</p>
<p><!-- /wp:paragraph --><!-- wp:image {"id":777,"sizeSlug":"full","linkDestination":"none"} --></p>
<figure class="wp-block-image size-full"><img src="../assets/images/image.png" decoding="async" class="wp-image-777"></figure>
<p><!-- /wp:paragraph --><!-- wp:paragraph {"fontSize":"medium"} --></p>						</div>
				</div>
				<div class="elementor-element elementor-element-2e47925 elementor-widget elementor-widget-heading" data-id="2e47925" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">14. Animaux</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-2ca0be4 elementor-widget elementor-widget-text-editor" data-id="2ca0be4" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>Aucun animal n'est admis à bord sauf dérogation exceptionnelle vu avec la direction au moment de la réservation. SYMONE se réserve le droit de refuser le Client à l'embarquement s'il se présente avec un animal.&nbsp;</p>						</div>
				</div>
				<div class="elementor-element elementor-element-43d8f7f elementor-widget elementor-widget-heading" data-id="43d8f7f" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">15. Retard et cas d'immobilisation du véhicule Symone</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-5bbdc29 elementor-widget elementor-widget-text-editor" data-id="5bbdc29" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>SYMONE s'engage à fournir ses meilleurs efforts pour respecter les horaires indiqués sur le Billet du Client.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Dans le cas où SYMONE pourrait raisonnablement s'attendre à ce que le départ du voyage soit retardé de plus de quatre-vingt-dix (90) minutes, le Client se verra offrir le choix entre :</p>
<p><!-- /wp:paragraph --><!-- wp:list --></p>

<ul>
<li style="list-style-type: none;">
<ul>
<li>la poursuite du voyage tel qu'il est établi dans le Contrat, dans des conditions comparables et dans les meilleurs délais ; et avec une indemnisation sur le prix du billet par personne à hauteur de 20%</li>
</ul>
</li>
</ul>
<p><!-- /wp:list-item --><!-- wp:list-item --></p>
<ul>
<li style="list-style-type: none;">
<ul>
<li>le remboursement du prix du Billet&nbsp;;&nbsp;</li>
</ul>
</li>
</ul>
<p><!-- /wp:list-item --></p>
<p><!-- /wp:list --><!-- wp:paragraph --></p>
<p>Dans le cas où le véhicule Symone deviendrait inutilisable au cours du voyage, l'entreprise proposera aux clients de terminer leur trajet en reprenant leur moto. Et SYMONE s'engage à rembourser la portion de trajet restante au même prix que cela leur aurait coûté s'ils avaient pris leur moto.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Ces modalités ne seront pas prises en compte en cas d'annulation ou de retard dû à des conditions météorologiques sévères ou à des catastrophes naturelles majeures compromettant l'exploitation de la prestation.</p>						</div>
				</div>
				<div class="elementor-element elementor-element-da6e77c elementor-widget elementor-widget-heading" data-id="da6e77c" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">16. Hygiène et sécurité à bord</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-9a48839 elementor-widget elementor-widget-text-editor" data-id="9a48839" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>SYMONE met en œuvre tous les moyens pour assurer la sécurité des clients.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Dans ce cadre le conducteur prend les mesures nécessaires afin d'assurer la sécurité des clients et donne, en cas de besoin, des instructions aux clients qui sont tenus de les respecter.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Des arrêts sont laissés à l'initiative du transporteur ou du conducteur pour répondre aux obligations de sécurité et de respect de la réglementation relative aux temps de conduite et de repos des conducteurs.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Le transport de marchandises dangereuses ou illicites est interdit dans les véhicules SYMONE à bord tels que notamment : les stupéfiants, les armes, les combustibles, tout type de feux d'artifices et pétards, fusées de détresse, allume-feux, gaz lacrymogènes ou encore les réchauds de camping…</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>À bord du véhicule SYMONE, le Client s'oblige à se conformer aux règles suivantes :</p>
<p><!-- /wp:paragraph --><!-- wp:list --></p>
<ul>
<li style="list-style-type: none;">
<ul><!-- wp:list-item --></ul>
</li>
</ul>
<ul>
<li style="list-style-type: none;">
<ul>
<li>obligation d'attacher sa ceinture de sécurité ;</li>
</ul>
</li>
</ul>
<p><!-- /wp:list-item --><!-- wp:list-item --></p>
<ul>
<li style="list-style-type: none;">
<ul>
<li>obligation de ne pas être en état d'ivresse ou d'ébriété ;</li>
</ul>
</li>
</ul>
<p><!-- /wp:list-item --><!-- wp:list-item --></p>
<ul>
<li style="list-style-type: none;">
<ul>
<li>interdiction de vapoter, de fumer, de consommer des stupéfiants et/ou de consommer de l'alcool, des substances illicites, ;</li>
</ul>
</li>
</ul>
<p><!-- /wp:list-item --><!-- wp:list-item --></p>
<ul>
<li style="list-style-type: none;">
<ul>
<li>obligation de respecter la propreté des lieux et du matériel ;</li>
</ul>
</li>
</ul>
<p><!-- /wp:list-item --><!-- wp:list-item --></p>
<ul>
<li style="list-style-type: none;">
<ul>
<li>interdiction de parler au conducteur pendant qu'il conduit ;</li>
</ul>
</li>
</ul>
<p><!-- /wp:list-item --><!-- wp:list-item --></p>
<ul>
<li style="list-style-type: none;">
<ul>
<li>respecter toutes les consignes de sécurité transmises par le conducteur ou le système d'information voyageurs ;</li>
</ul>
</li>
</ul>
<p><!-- /wp:list-item --><!-- wp:list-item --></p>
<ul>
<li style="list-style-type: none;">
<ul>
<li>obligation de s'installer à la place indiquée par le conducteur;</li>
</ul>
</li>
</ul>
<p><!-- /wp:list-item --><!-- wp:list-item --></p>
<ul>
<li style="list-style-type: none;">
<ul>
<li>obligation de se comporter calmement et silencieusement ;</li>
</ul>
</li>
</ul>
<p><!-- /wp:list-item --><!-- wp:list-item --></p>
<ul>
<li style="list-style-type: none;">
<ul>
<li>obligation de ne pas utiliser d'appareil bruyant ou sonore (sauf utilisation d'un casque) ;</li>
</ul>
</li>
</ul>
<p><!-- /wp:list-item --></p>
<p><!-- /wp:list --><!-- wp:paragraph --></p>
<p>Concernant les clients accompagnés de mineurs, ceux-ci devront être gardés, sous leur responsabilité et faire appliquer ces mêmes règles.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Pendant les arrêts, le Client doit respecter les règles suivantes :</p>
<p><!-- /wp:paragraph --><!-- wp:list --></p>
<ul>
<li style="list-style-type: none;">
<ul><!-- wp:list-item --></ul>
</li>
</ul>
<ul>
<li style="list-style-type: none;">
<ul>
<li>obligation de descendre de l'autocar pendant les pauses lorsque le conducteur le demande ;</li>
</ul>
</li>
</ul>
<p><!-- /wp:list-item --><!-- wp:list-item --></p>
<ul>
<li style="list-style-type: none;">
<ul>
<li>au cas où le conducteur autoriserait les clients à rester dans l'autocar durant les pauses, ceux-ci doivent s'abstenir de déranger le conducteur durant sa pause ;</li>
</ul>
</li>
</ul>
<p><!-- /wp:list-item --><!-- wp:list-item --></p>
<ul>
<li style="list-style-type: none;">
<ul>
<li>respecter la durée des arrêts fixés, le conducteur s'accorde le droit de repartir dans l'hypothèse où un Client ne respecte pas la durée de l'arrêt fixée, sans être tenu responsable de l'absence dudit Client.</li>
</ul>
</li>
</ul>						</div>
				</div>
				<div class="elementor-element elementor-element-005cd4d elementor-widget elementor-widget-heading" data-id="005cd4d" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">17. Responsabilité</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-2bbbc6d elementor-widget elementor-widget-text-editor" data-id="2bbbc6d" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>SYMONE s'engage à mettre en œuvre toutes ses compétences et ses ressources pour apporter satisfaction au Client durant l'exécution de ses obligations contractuelles.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>La responsabilité de SYMONE ne peut être engagée par le Client que dans les conditions du droit commun.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>En conséquence, la responsabilité de SYMONE pourra être engagée en cas de manquement à l'une de ses obligations contractuelles sauf cas de force majeure empêchant l'exécution de l'obligation concernée&nbsp;à savoir&nbsp;: un événement échappant au contrôle de SYMONE qui ne pouvait être raisonnablement prévu lors de la conclusion du Contrat et dont les effets ne peuvent être évités par des mesures appropriées conformément à la définition légale de l'article 1218 du Code civil.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>En tout état de cause, SYMONE ne pourra être tenu que des dommages et intérêts prévisibles lors de la conclusion du Contrat et dans les limites spécifiques prévues au présent Contrat.</p>						</div>
				</div>
				<div class="elementor-element elementor-element-0e0b602 elementor-widget elementor-widget-heading" data-id="0e0b602" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">18. Réclamation&nbsp;</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-d9bd9f0 elementor-widget elementor-widget-text-editor" data-id="d9bd9f0" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>Toute réclamation doit être adressée soit à l'aide du formulaire de contact en ligne sur le site <a href="http://www.symone.fr">www.symone.fr</a>&nbsp; ou par mail à&nbsp;: <a href="mailto:reservation@symone.fr">reservation@symone.fr</a>&nbsp; OU <a href="mailto:contact@symone.fr">contact@symone.fr</a>, ou bien par courrier recommandé avec demande d'avis de réception aux coordonnées suivantes :</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p><br>
<div style='text-align: center;'>
<p>SAS SYMONE</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>75 b avenue du drapeau</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>21000 DIJON (France)</p><br>
</div>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>La réclamation doit être adressée dans un délai de 8 jours à compter de la date de défaillance et être accompagnée du Billet, sous peine de ne pas être traitée par l'entreprise SYMONE.&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Afin de garantir le maximum d'efficacité dans le traitement de sa réclamation, le Client est invité à préciser son identité ainsi qu'à détailler les motifs et circonstances de sa réclamation.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>SYMONE s'engage à informer le Client sur le statut de sa réclamation (retenue, rejetée ou toujours en examen) dans un délai d'un (1) mois à compter de la réception de la réclamation. En tout état de cause, une réponse définitive est envoyée au Client dans un délai de deux (2) mois à compter de la réception de la réclamation.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph {"fontSize":"medium"} --></p>
<p class="has-medium-font-size">&nbsp;</p>						</div>
				</div>
				<div class="elementor-element elementor-element-a7add40 elementor-widget elementor-widget-heading" data-id="a7add40" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">19. Assurances&nbsp;</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-334f129 elementor-widget elementor-widget-text-editor" data-id="334f129" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>En complément des garanties au titre des présentes Conditions de vente et des assurances obligatoires dont dispose SYMONE ; le Client est invité à souscrire toute police d'assurance complémentaire et notamment un contrat d'assistance couvrant certains risques particuliers notamment les frais de rapatriement en cas d'accident ou de maladie.</p>						</div>
				</div>
				<div class="elementor-element elementor-element-1044ac5 elementor-widget elementor-widget-heading" data-id="1044ac5" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">20. Propriété intellectuelle</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-5578e54 elementor-widget elementor-widget-text-editor" data-id="5578e54" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>Tous les éléments, tous les contenus présents sur le Site ou les documents transmis par SYMONE sont protégés par un droit de propriété intellectuelle et sont la propriété de SYMONE ou de ses Partenaires. Tout acte d'exploitation de ces éléments, contenus ou documents non autorisés par SYMONE (reproduction, représentation, extraction, réutilisation, etc…), en tout ou partie, est constitutive de contrefaçon pouvant engager la responsabilité civile et pénale de son auteur.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Le Site ou toute partie de celui-ci ne doit en aucun cas être reproduit, copié, vendu ou exploité pour des raisons commerciales sans notre autorisation expresse et écrite. Il est interdit d'utiliser des techniques permettant de copier une marque, un logo ou toute autre information dont SYMONE est propriétaire sans l'accord exprès et écrit de SYMONE.</p>						</div>
				</div>
				<div class="elementor-element elementor-element-e675636 elementor-widget elementor-widget-heading" data-id="e675636" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">21. Documents contractuels</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-b2938f4 elementor-widget elementor-widget-text-editor" data-id="b2938f4" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>Le Contrat est constitué des documents contractuels suivants : le Billet et les présentes CGV.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>En cas de contradiction ou de divergence entre les stipulations des deux documents, la stipulation du document supérieur en rang prévaudra : le Billet prévaut sur CGV.&nbsp;</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>Aucune mention portée par le Client ou son éventuel Mandataire, sur un quelconque document autre que, le cas échéant, sa signature ne sera considérée comme contractuelle à défaut d'acceptation expresse par SYMONE.</p>
<p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
<p>L'ensemble des documents contractuels susvisés représente l'intégralité des engagements existant entre les Parties. Ces documents contractuels remplacent et annulent tout éventuel engagement oral ou écrit antérieur relatif à la Commande visée.</p>						</div>
				</div>
				<div class="elementor-element elementor-element-7abf8da elementor-widget elementor-widget-heading" data-id="7abf8da" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">22. Validité</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-e9eb253 elementor-widget elementor-widget-text-editor" data-id="e9eb253" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>Si une ou plusieurs stipulations des CGV venai(en)t à être déclarée(s) nulle(s), non écrite(s) ou non opposable(s) en application d'une loi, d'un règlement ou à la suite d'une décision définitive d'une juridiction compétente, cette ou ces stipulation(s) devra ou devront être considérée(s) comme détachable(s) des CGV. Les autres stipulations des CGV seront considérées comme valides, et resteront en vigueur, à moins que l'une des Parties ne démontre que la ou les stipulation(s) annulée(s) revêt(ent) un caractère essentiel et déterminant sans lequel elle n'aurait pas contracté.</p>						</div>
				</div>
				<div class="elementor-element elementor-element-ca7eac9 elementor-widget elementor-widget-heading" data-id="ca7eac9" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">23. Tolérances</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-3586c15 elementor-widget elementor-widget-text-editor" data-id="3586c15" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>Le fait pour l'une des Parties de ne pas se prévaloir d'un manquement de l'autre Partie à l'une quelconque de ses obligations issues du Contrat ne saurait être interprété comme une renonciation à l'exécution de l'obligation en cause en l'absence de prescription.</p>						</div>
				</div>
				<div class="elementor-element elementor-element-375e587 elementor-widget elementor-widget-heading" data-id="375e587" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container optml-bg-lazyloaded"><br>
			<h3 class="elementor-heading-title elementor-size-default">24. Loi applicable et tribunaux compétents</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-bb5b887 elementor-widget elementor-widget-text-editor" data-id="bb5b887" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container optml-bg-lazyloaded">
							<p>Le Contrat est soumis au droit français, dans la limite des dispositions d'ordre public des lois locales applicables. Tout litige relatif à leur interprétation et/ou à leur exécution relève des tribunaux compétents.</p>						</div>
				</div>
					</div>
        </div>

          </div>
        </div>
        <div class="footer">
          <div style="margin: 50px">
            <div
              style="
                font-size: 20px;
                color: #e6007e;
                font-weight: 400;
                text-align-last: center;
              "
            >
              SUIVEZ-NOUS
              <br />
              <a href="https://fr.linkedin.com/company/symone" target="_blank"
                ><i
                  class="fab fa-linkedin"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a href="https://twitter.com/SymoneMobility" target="_blank"
                ><i
                  class="fab fa-twitter"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a
                href="https://www.instagram.com/symonemobility/?hl=fr"
                target="_blank"
                ><i
                  class="fab fa-instagram"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a href="https://www.facebook.com/SymoneMobility/" target="_blank"
                ><i
                  class="fab fa-facebook"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <br />
              <br />
              NOUS CONTACTER
              <br />
              <span style="color: #000047"
                >contact@symone.fr<br />06 17 33 43 93</span
              >
              <br />
              <br />
              ÉCRIVEZ-NOUS
              <br />
              <a href="/contact" target="_blanc"
                ><span style="color: #000047">Contact</span></a
              >
            </div>
            <div
              style="
                font-size: 20px;
                color: #000047;
                font-weight: 400;
                text-align-last: center;
              "
            >
              <br />
              <a href="/" target="_blank">Bienvenue à bord</a>
              <br /><br />
              <a href="/moto" target="_blank">Symone à moto</a>
              <br /><br />
              <a href="/#home-tag-2" target="_self"
                >Une nouvelle expérience de la route</a
              >
              <br /><br />
              <a
                href="/condition"
                target="_blank"
                >Conditions Générales de Vente</a
              >
              <br />
            </div>
          </div>
          <img src="@/assets/images/ui/logo_couleur (1).png" alt="Logo" />
        </div>
      </div>
  </template>
  
  <script>

  export default {
    name: "ConditionPage",
    components: { 
    },
    data() {
      return {
      };
    },
	created(){
		document.title= 'Conditions de vente - Symone'
	},
    watch: {
    },
    mounted(){
    },
    methods: {
      formattedDate(date){
        return String(date).slice(6, 8) + "-" + String(date).slice(4, 6) + "-" + String(date).slice(0, 4);
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  *{
    text-decoration: none;
  }
.border {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
  .container{
    color: #000047;
  width: 100%;
}
  .title{
    color: #000047;
    font-size: 50px;
    font-weight: 600;
    
  background-image: url("../assets/images/ui/aile-couleur-transparant.png");
    min-height: 250px;
    background-size: cover;
    background-position: bottom;
    width: 100%;
    
    display: flex;    
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
.nav {
  padding: 25px 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: #000047;
}
.txt{
  max-width: 1000px;
  text-align: left;
  *{
    margin: revert;
  }
  img{
    max-width: 100%;

  }
}
.w-1{
  width: 100%;
  text-align: -webkit-center;
}
.menu {
  display: flex;
}
.menu a {
  color: white;
  margin: 0 10px;
}
.details {
  max-width: 40vw;
  color: #dddddd;
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.cover img{
  max-width: 60vw;
  max-height: 50vh;
}

.footer {
  height: 100vh;
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  img{
    max-width: 100%;
  }
}
a{
  color: #e6007e;
}
.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}
</style>
  
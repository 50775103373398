import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'

import { createHead } from '@vueuse/head'

const app = createApp(App);

const head = createHead()

app.use(head)

app.config.globalProperties.pageTitle = ''; 
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Symone';
  app.config.globalProperties.pageTitle = document.title;
  next();
});

app.use(ElementPlus);
app.use(router).mount('#app');
<template>
  <div id="container">
    <div class="home-tag-1 full-screen-element">
      <div class="nav" style="position: fixed; z-index: 2">
        <a href="/" target="_self"
          ><img
            src="../assets/images/ui/logo.png"
            style="max-width: 150px; margin: 0 20px"
        /></a>
        <div class="menu details">
          <a href="/" target="_self">Accueil</a>
          <a href="/moto" target="_self">Symone à moto</a>
          <a href="/news" target="_self">Actus</a>
          <a href="/contact" target="_blank"
            >Contact</a
          >
          <a href="/faq" target="_blank">FAQ</a>
          <a href="/" target="_self"
            ><img
              src="../assets/images/ui/france.png"
              style="width: 25px; height: 25px"
          /></a>
          <a href="/en" target="_self"
            ><img
              src="../assets/images/ui/royaume-uni.png"
              style="width: 25px; height: 25px"
          /></a>
        </div>
      </div>
      <div class="home-tag-1-autocar">
        <div class="home-tag-1-top">
          <div class="home-tag-1-top-title title">
            Bienvenue à bord de Symone ! Dès 2025 ...
          </div>
          <div style="display: flex; align-items: center">
            <div class="home-tag-1-top-details details">
              Symone repense la manière de se déplacer sur les longs trajets
              autoroutiers en alliant gain de temps libre, écologie et sécurité
              routière
            </div>
            <div
              @click="scrollToTag2()"
              class="btn-trans-white"
              style="width: auto; text-align: center; margin: 0 0 0 10px"
            >
              En savoir plus
            </div>
          </div>
        </div>
      </div>
      <div class="home-tag-1-moto">
        <div class="home-tag-1-bottom">
          <div class="home-tag-1-bottom-title title">
            ... et dès aujourd'hui pour les motards !
          </div>
          <div style="display: flex; align-items: center">
            <div
              class="btn-trans-white"
              style="width: auto; text-align: center; margin: 0 10px 0 0"
            >
              <a href="/moto" target="_self">En savoir plus</a>
            </div>
            <div class="home-tag-1-bottom-details details">
              Symone vous transporte avec votre moto aux portes de vos terrains
              de jeux
            </div>
          </div>
        </div>
        <div class="home-tag-1-filter">
          <form id="homeFilter">
            <Autocomplete
              class="autocomplete"
              v-model="search.depart"
              placeholder="Départ"
            ></Autocomplete>
            <Autocomplete
              class="autocomplete"
              v-model="search.arrival"
              placeholder="Destination"
            ></Autocomplete>
          </form>
            <button class="btn-rose" style="float: right" @click='sendResarchOptions'>
              Rechercher
            </button>
        </div>
        <div id="scrolling-container">
          <img src="../assets/images/voiture-remorque.png" alt="car" />
        </div>
      </div>
    </div>
    <div
      class="capital-raising-m full-screen-element"
      data-element_type="container"
      style="display: none;"
    >
      <h2 class="elementor-heading-title elementor-size-default">
        Vous avez manqué notre dernière levée de fonds ?
      </h2>
      <br />
      <p data-testid="ouah_q1yiAk5EyZMYlCKe">
        Suite à plusieurs demandes et à une opportunité d'abondement
        institutionnel complémentaire, nous laissons ouverte une deuxième
        souscription jusqu'à fin juin 2024 seulement. Aux mêmes conditions
        mais avec un montant très limité de 200 000 euros maximum !
      </p>
      <br />
      <p data-testid="ouah_q1yiAk5EyZMYlCKe">
            Ne tardez pas, dernière chance de devenir actionnaire de Symone à partir de 500 euros : 
          </p>
          <p>
            <span style="color: #e6007e"
              ><strong
                ><a
                  style="color: #e6007e"
                  href="https://sowefund.com/projet/177/symone"
                  >https://sowefund.com/projet/177/symone</a
                ></strong
              ></span
            >
          </p>
    </div>
    <div class="home-tag-2 full-screen-element">
      <video ref="videoPlayer" autoplay muted playsinline loop>
        <source
          src="../assets/videos/SYMONE_SHORT_V2_SANS_TEXTES_COUPE - Compressed with FlexClip.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <div class="overlay">
        <div class="title border" style="max-width: 80vw">
          Symone, une nouvelle expérience de la route
        </div>
        <div class="details" style="max-width: 80vw">
          Symone propose sur l'autoroute un nouveau mode de déplacement
          écologique, partagé et unique au monde. Symone c'est un grand autocar
          fonctionnant à l'hydrogène décarboné, pour se déplacer avec son
          véhicule sans le conduire
        </div>
        <div
          class="capital-raising"
          data-id="9be358d"
          data-element_type="container"
          id="popup"
          data-settings='{"content_width":"full","background_background":"classic","position":"absolute"}'
        >
          <div style="width: 100%; display: flex; justify-content: end; z-index: 4;">
            <svg
              @click="hideCapitalRising()"
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="none"
              viewBox="0 0 22 22"
            >
              <circle
                cx="11"
                cy="11"
                r="10"
                stroke="#fff"
                stroke-width="1.5"
              ></circle>
              <path
                stroke="#fff"
                stroke-linecap="round"
                stroke-width="1.5"
                d="m7 7 7.071 7.071m0-7.071L7 14.071"
              ></path>
            </svg>
          </div>
          <h2 class="elementor-heading-title elementor-size-default">
            Vous avez manqué notre dernière levée de fonds ?
          </h2>
          <br />
          <p data-testid="ouah_q1yiAk5EyZMYlCKe">
            Suite à plusieurs demandes et à une opportunité d'abondement
            institutionnel complémentaire, nous laissons ouverte une deuxième
            souscription jusqu'à fin juin 2024 seulement. Aux mêmes conditions
            mais avec un montant très limité de 200 000 euros maximum !
          </p>
          <br />
          <p data-testid="ouah_q1yiAk5EyZMYlCKe">
            Laissez-nous votre mail sur ce formulaire pour que nous puissions
            vous envoyer des précisions. Places limitées !
          </p>
          <br />
          <p>
            <span style="color: #e6007e"
              ><strong
                ><a
                  style="color: #e6007e"
                  href="https://invest.sowefund.com/avenir/symone"
                  >Formulaire de demande d'informations</a
                ></strong
              ></span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="home-tag-7 full-screen-element">
      <div
        style="
          width: 100%;
          height: 90vh;
          overflow-y: scroll;
          padding-bottom: 50px;
          text-align: -webkit-center;
        "
      >
        <div class="card-row">
          <div class="card-image">
            <img src="../assets/images/Group-52.png" alt="Image" />
          </div>
          <div class="card-content">
            <h3 style="font-size: 25px; margin: 10px 0; color: #000047">
              Réservation facilitée
            </h3>
            <p style="margin-bottom: 10px; color: #000047">
              La réservation sera simple et pratique : en quelques clics, grâce
              à l'application Symone vous réservez votre billet pour votre
              véhicule, quel que soit le nombre de passagers. Aussi simple que
              de réserver un trajet en train.
            </p>
          </div>
        </div>
        <div class="card-row">
          <div class="card-image">
            <img src="../assets/images/SYMONE2.jpg" alt="Image" />
          </div>
          <div class="card-content">
            <h3 style="font-size: 25px; margin: 10px 0; color: #000047">
              Embarquement rapide
            </h3>
            <p style="margin-bottom: 10px; color: #000047">
              En amont du péage autoroutier, des professionnels embarquent votre
              voiture ou votre moto tandis que vous prenez place dans la cabine
              tout confort de votre Symone. Un temps de chargement transparent
              pour vous pour commencer à profiter des nombreux services à bord.
            </p>
          </div>
        </div>
        <div class="card-row">
          <div class="card-image">
            <img src="../assets/images/Image6-1536x1119-1.jpeg" alt="Image" />
          </div>
          <div class="card-content">
            <h3 style="font-size: 25px; margin: 10px 0; color: #000047">
              Temps gagné
            </h3>
            <p style="margin-bottom: 10px; color: #000047">
              Durant le trajet, vous disposez de votre temps, et bénéficiez des
              services proposés à bord. Une voiture électrique ? Faites de votre
              Symone un prolongateur d'autonomie !
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="home-tag-3 full-screen-element">
      <div class="slideshow" id="slideshow">
        <div class="home-tag-3-loop home-tag-3-loop-1">
          <div class="home-tag-3-logo">
            <span class="material-symbols-outlined"> accessible </span>
          </div>
          <div class="home-tag-3-title title">Accessibilité</div>
          <div class="home-tag-3-details details">
            Une Symone peut accueillir jusqu'à 20 passagers dont plusieurs
            personnes à mobilité réduite (PMR)
          </div>
        </div>
        <div class="home-tag-3-loop home-tag-3-loop-2">
          <div class="home-tag-3-logo">
            <span class="material-symbols-outlined"> eco </span>
          </div>
          <div class="home-tag-3-title title">Écologie</div>
          <div class="home-tag-3-details details">
            Fonctionnant grâce à l'hydrogène vert, une Symone ne rejette que
            quelques gouttes d'eau à l'usage et contribue à la décarbonation de
            nos routes. En phase de fonctionnement, jusqu'à 550 tonnes de CO2 en
            moins par an et par Symone H2 en circulation ! Le prototype
            fonctionnera au bioGNV (gaz)
          </div>
        </div>
        <div class="home-tag-3-loop home-tag-3-loop-3">
          <div class="home-tag-3-logo">
            <span class="material-symbols-outlined"> lock </span>
          </div>
          <div class="home-tag-3-title title">Sécurité</div>
          <div class="home-tag-3-details details">
            Notre conducteur expérimenté vous emmènera à destination en toute
            sécurité sur le réseau autoroutier, de jour comme de nuit
          </div>
        </div>
        <div class="home-tag-3-loop home-tag-3-loop-4">
          <div class="home-tag-3-logo">
            <span class="material-symbols-outlined"> hail </span>
          </div>
          <div class="home-tag-3-title title">Pratique</div>
          <div class="home-tag-3-details details">
            Vous montez rapidement sur une Symone directement au péage avec
            votre véhicule, avec autant de bagages et de personnes que vous
            souhaitez. Une Symone peut transporter 6 à 10 véhicules (voitures ou
            motos). Profitez de vos longs trajets autoroutiers pour vous
            reposer, pour les loisirs, pour passer du temps en famille ou pour
            travailler
          </div>
        </div>

        <div class="home-tag-3-loop home-tag-3-loop-5">
          <div class="home-tag-3-logo">
            <span class="material-symbols-outlined"> concierge </span>
          </div>
          <div class="home-tag-3-title title">Service</div>
          <div class="home-tag-3-details details">
            Voyagez l'esprit libre dans des cabines compartimentées tout confort
            équipées de multiples services (wifi, écrans vidéo, fauteuil grand
            confort…) pour une expérience optimale. Commodité et restauration
            sont aussi disponibles
          </div>
        </div>
      </div>
    </div>
    <div class="home-tag-9 full-screen-element">
      <div style="padding: 0 20px; text-align: -webkit-center">
        <div
          class="border-white title-l"
          style="font-size: 6vw; margin-bottom: 50px"
        >
          <b
            ><span style="color: #e6007e">D</span>ès 2025, un premier véhicule
            en circulation</b
          >
        </div>
        <div class="card-row">
          <div class="card-content">
            <p style="margin-bottom: 10px; color: #000047">
              Symone c'est un long véhicule fonctionnant à l'hydrogène décarboné
              qui vous attend sur votre route, au péage. On charge votre voiture
              ou votre moto et vous montez à l'avant de la Symone dans une
              cabine passagers tout confort, comme dans un train en première
              classe. Sur deux niveaux, une Symone accueille en bas jusqu'à 20
              passagers dans un espace tout confort et embarque jusqu'à 10
              voitures et motos.
            </p>
          </div>
          <div class="card-image">
            <img src="../assets/images/PARTENARIATS.png" alt="Image" />
          </div>
        </div>
        <div class="card-row">
          <div class="card-content">
            <p style="margin-bottom: 10px; color: #000047">
              Dès 2025, avec un véhicule au BioGNV, Symone proposera une
              nouvelle expérience de la route, l'esprit libre. De jour comme de
              nuit, imaginez le confort de vous laisser transporter sur
              l'autoroute pour arriver frais et reposé à votre destination avec
              votre véhicule. Vous avez pu dormir, travailler, ou passer plus de
              temps en famille. Sur des trajets maintenant plus sûrs et plus
              décarbonés. <br /><br />Symone c'est un nouveau mode de
              déplacement écologique et partagé pour les particuliers comme pour
              les professionnels. Un formidable outil pour la transition
              écologique. C'est aussi une mobilité des territoires, pour tous et
              plus inclusive.
            </p>
          </div>
          <div class="card-image">
            <img src="../assets/images/PARTENARIATS (1).png" alt="Image" />
          </div>
        </div>
      </div>
    </div>
    <div class="home-tag-4 full-screen-element">
      <div
        style="
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          align-items: center;
          text-align-last: center;
          padding: 50px;
          box-sizing: border-box;
          background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.6)
          );
        "
      >
        <div
          class="home-tag-4-card"
          style="display: flex; flex-wrap: wrap; justify-content: center"
        >
          <div class="card">
            <div class="card-bg">
              <img class="avatar" src="../assets/images/romain.jpg" />
              <div class="firstname">Romain</div>
              <div class="lastname">Coispine</div>
              <div
                class="post"
                style="color: #e6007e; margin-top: 20px; margin-bottom: 20px"
              >
                CEO
              </div>
              <a
                href="https://www.linkedin.com/in/romain-coispine-311b0a174/"
                target="_blanc"
                ><i
                  class="fab fa-linkedin"
                  style="color: white; font-size: 40px"
                ></i
              ></a>
            </div>
          </div>
          <div class="card">
            <div class="card-bg">
              <img class="avatar" src="../assets/images/1662033571674.jpeg" />
              <div class="firstname">Yoann</div>
              <div class="lastname">Lacombe</div>
              <div
                class="post"
                style="color: #e6007e; margin-top: 20px; margin-bottom: 20px"
              >
                COO
              </div>
              <a
                href="https://www.linkedin.com/in/yoann-lacombe-6b8679ab/"
                target="_blank"
                ><i
                  class="fab fa-linkedin"
                  style="color: white; font-size: 40px"
                ></i
              ></a>
            </div>
          </div>
          <div
            class="home-tag-4-details"
            style="color: white; text-align-last: left"
          >
            <div style="font-size: 40px; margin-bottom: 15px" class="border">
              <b><span style="color: #e6007e">L</span>es fondateurs</b>
            </div>
            <div style="font-size: 18px" class="border">
              Yoann et Romain se sont rencontrés via leur engagement bénévole au
              sein de la Jeune Chambre Economique française à Dijon. Ils y ont
              mené des projets d'intérêt général et se sont retrouvés
              naturellement dans ce projet de création d'entreprise. Ce sont,
              entre-autres, les valeurs du collectif et sens de la
              responsabilité qui les ont rapprochés. <br /><br /><em
                >«Nous savons que le chemin de création d'une entreprise est
                long et semé d'embûches. Avec engagement, humilité et audace
                nous appréhendons chaque difficulté comme une opportunité et
                tentons l'impossible.»</em
              ><br /><br />
              Trois grands axes de développement les poussent depuis 2 ans à
              voir ce projet comme une « raison d'être » plutôt qu'une simple
              création d'entreprise, aussi innovante soit-elle. « Avec Symone,
              nous pourrons rendre nos autoroutes plus sûres, plus vertes et
              décarbonées ».
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-tag-5 full-screen-element">
      <div
        class="title-l"
        style="
          margin-top: 40px;
          font-size: 6vw;
          font-weight: 600;
          color: white;
          text-align-last: center;
        "
      >
        <span style="color: #e6007e">P</span>rix &
        <span style="color: #e6007e">D</span>istinctions
      </div>
      <div class="home-tag-5-list">
        <div class="card-price" v-for="(item, index) in arr_price" :key="index">
          <div class="card-image">
            <img :src="item.url" alt="Image" />
          </div>
          <div class="card-content">
            <h3 style="font-size: 25px; margin-bottom: 10px">
              {{ item.title }}
            </h3>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="home-tag-6 full-screen-element" id="home-tag-6">
      <div
        style="
          font-size: 20px;
          font-weight: 300;
          color: white;
          float: right;
          text-align: right;
        "
        class="border"
      >
        <a href="/news" target="_self"><u>Voir toutes les actus</u></a>
      </div>
      <div class="news-list">
                
        <el-carousel :interval="4000" type="card" height="300px" width="100%">
            <el-carousel-item v-for="item in news" :key="item">
                <div class="card-image">
                    <img :src="`${config.API_BASE_URL}/assets/images/news/${item.cover}`" alt="Cover" />
                </div>
                <div class="card-content">
                    <h3 style="font-size: 25px; margin-bottom: 10px; color: #000047">
                    {{ item.title }}
                    </h3>
                    <button class="btn-trans-blue"><a :href="`/news/${item.id}`" target="_blank">En savoir plus</a></button>
                </div>
            </el-carousel-item>
        </el-carousel>
      </div>
      <div
        class='title-l'
        style="
          font-size: 6vw;
          font-weight: 600;
          color: white;
          margin-top: 20px;
        "
      >
        <span style="color: #e6007e">D</span>écouvrez nos dernières actus
      </div>
    </div>
    <div class="home-tag-8 full-screen-element">
      <div
        class="border title-l"
        style="
          margin-right: auto;
          margin-top: 30px;
          font-size: 6vw;
          color: #000047;
          font-weight: 600;
          color: white;
          text-align: center;
        "
      >
        <span style="color: #e6007e">I</span>ls parlent de nous
      </div>
      <div class="extra-news-list">
        <div class="scrollable-container">
          <div
            class="card-extra-news"
            v-for="(item, index) in arr_extra_news"
            :key="index"
          >
            <div class="card-image">
              <img :src="item.logo" alt="Image" />
            </div>
            <a :href="item.url" target="_blanc"
              ><div class="card-content">
                <h3 style="font-size: 25px; margin: 10px 0; color: #000047">
                  {{ item.title }}
                </h3>
                <p style="margin-bottom: 10px; color: #000047">
                  {{ item.description }}
                </p>
                <img :src="item.photo" style="width: 100%" alt="Image" /></div
            ></a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer full-screen-element">
      <div style="width: -webkit-fill-available">
        <div
          class="title-l"
          style="
            font-size: 6vw;
            font-weight: 600;
            color: #000047;
            text-align: center;
          "
        >
          <span style="color: #e6007e">I</span>ls nous soutiennent
        </div>
        <div
          style="
            margin-top: 10px;
            margin-bottom: 50px;
            color: #000047;
            font-size: 18px;
            text-align: center;
          "
        >
          Symone peut compter sur le soutien d'acteurs majeurs du développement
          économique et de la mobilité.
        </div>
        <div class="sponsor-list">
          <div
            class="slides-container"
            v-for="(item, index) in arr_sponsors"
            :key="index"
          >
            <img
              :src="item"
              alt="Logo"
              style="display: inline-block; max-width: 200px"
            />
          </div>
          <button class="prev-btn">&lt;</button>
          <button class="next-btn">&gt;</button>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-evenly;
            width: 100%;
            margin-top: 50px;
          "
        >
          <img
            src="@/assets/images/ui/logo_couleur (1).png"
            alt="Logo"
            style="width: 200px; height: fit-content; align-self: center"
          /><br /><br />

          <div
            style="
              font-size: 20px;
              color: #000047;
              font-weight: 400;
              text-align-last: center;
            "
          >
            <br />
            <a href="/" target="_self">Bienvenue à bord</a>
            <br /><br />
            <a href="/moto" target="_blank">Symone à moto</a>
            <br /><br />
            <a href="/#home-tag-2" target="_blank"
              >Une nouvelle expérience de la route</a
            >
            <br /><br />
            <a
              href="/condition"
              target="_blank"
              >Conditions Générales de Vente</a
            >
            <br />
          </div>

          <div
            style="
              font-size: 20px;
              color: #e6007e;
              font-weight: 400;
              text-align-last: center;
            "
          >
            SUIVEZ-NOUS
            <br />
            <a href="https://fr.linkedin.com/company/symone" target="_blank"
              ><i
                class="fab fa-linkedin"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <a href="https://twitter.com/SymoneMobility" target="_blank"
              ><i
                class="fab fa-twitter"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <a
              href="https://www.instagram.com/symonemobility/?hl=fr"
              target="_blank"
              ><i
                class="fab fa-instagram"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <a href="https://www.facebook.com/SymoneMobility/" target="_blank"
              ><i
                class="fab fa-facebook"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <br />
            <br />
            NOUS CONTACTER
            <br />
            <span style="color: #000047"
              >contact@symone.fr<br />06 17 33 43 93</span
            >
            <br />
            <br />
            ÉCRIVEZ-NOUS
            <br />
            <a href="/contact" target="_blanc"
              ><span style="color: #000047">Contact</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Autocomplete from "@/components/Autocomplete.vue";
import axios from 'axios';
import config from '@/config';

export default {
  name: "HomePage",
  components: {
    Autocomplete,
  },
  data() {
    return {
      config: config,
      allowTrigger: false,
      homeDepart: null,
      homeArrival: null,
      items: [],
      currentIndex: 0,
      currentTag: 0,
      slideIndex: 0,
      timer: "",
      images: [
        "chaise-roulante.jpg",
        "SYMONE2.jpg",
        "Image6-1536x1119-1.jpeg",
        "SYMONE3.jpg",
        "Image8_modif2.png",
      ],
      arr_sponsors: [
        require("@/assets/images/ui/Logo-Ademe-2020-qe5vewri8j2htnbjzkwgr486pdu5ahuodrnho0n93o.png"),
        require("@/assets/images/ui/Logo_Vinci-Autoroutes-e1685543038498-qe5vend4c6pmljp7igu726lkrj4h5itd0h4mv91534.png"),
        require("@/assets/images/ui/Logo_France_Relance-qe5vewri8j2htnbjzkwgr486pdu5ahuodrnho0n8sk.png"),
        require("@/assets/images/ui/Logo_Bpifrance-e1685543068296-qe5vend4c6pmljp7igu726lkrj4h5itd0h4mv91552.png"),
        require("@/assets/images/ui/logoBFC-qe5vewri8j2htnbjzkwgr486pdu5ahuodrnho0n7ya.png"),
        require("@/assets/images/ui/Capture-décran-2023-06-03-à-15.06.52.png"),
      ],
      arr_price: [
        {
          title: "Labellisé ESUS",
          url: require("../assets/images/ui/Capture-décran-2023-06-03-à-14.59.04.png"),
          description: `Délivré par le Ministère de l'Economie, des finances et de la souveraineté numérique.`,
        },
        {
          title: "Labellisé Véhicule du futur",
          url: require("../assets/images/ui/Capture-décran-2023-06-03-à-15.06.52.png"),
          description: `Le Pôle Véhicule du Futur est une association qui favorise la synergie des entreprises, de l'enseignement et de la recherche dans le domaine des véhicules et des mobilités du futur.`,
        },
        {
          title: "Lauréat Hydrogénies 2022",
          url: require("../assets/images/ui/Capture-décran-2023-06-03-à-15.16.41.png"),
          description: `Prix “Coup de cœur et projet disruptif" des trophées de l'hydrogène 2022 à l'Assemblée nationale.`,
        },
        {
          title: 'Finaliste "10K startups pour changer le monde"',
          url: require("../assets/images/ui/Capture-décran-2023-06-03-à-15.24.10.png"),
          description: `Symone remporte la finale régionale du concours organisé par La Tribune et BNP Paribas, dans la catégorie Start.`,
        },
        {
          title: "Easy mobility challenge",
          url: require("../assets/images/ui/logo_1_.png"),
          description: `Symone termine deuxième de l'Easy Mobility Challenge organisé par CANAL+ et SEAT.`,
        },
        {
          title: "Membre Deeptech BPI France",
          url: require("../assets/images/ui/Capture-décran-2023-06-05-à-11.33.55.png"),
          description: `Sélectionné en nov. 2022, Symone est membre de "l'écosystème de startups qui changent le monde grâce à l'innovation de rupture".`,
        },
        {
          title: "Parlement européen",
          url: require("../assets/images/ui/Thearch.png"),
          description: `Symone a été présenté au Parlement européen dans les 100 solutions pour la planète.`,
        },
        {
          title: "Lauréat international Michelin",
          url: require("../assets/images/ui/movinon.png"),
          description: `"Un exemple frappant de mobilité sûre et efficace qui répond aux attentes de l'utilisateur final et soutient un modèle commercial viable pour le déplacement des personnes dans le respect de l'environnement."`,
        },
      ],
      arr_extra_news: [
        {
          title: "Europe 1",
          photo: require("../assets/images/Capture-décran-2023-07-13-à-14.36.13-1.png"),
          logo: require("../assets/images/ui/Europe1_FLAT.png"),
          url: `https://www.europe1.fr/emissions/la-france-bouge-academie/symone-propose-des-bus-pour-transporter-des-vehicules-et-leurs-passagers-sur-lautoroute-4188380`,
          description: `"Symone propose des bus pour transporter des véhicules et leurs passagers sur l'autoroute."`,
        },
        {
          title: "RMC STORY",
          photo: require("../assets/images/Capture-décran-2023-07-13-à-15.37.29.png"),
          logo: require("../assets/images/ui/RMC-Story-logo.png"),
          url: `https://rmc.bfmtv.com/actualites/tech/des-voitures-sur-le-toit-d-un-bus-un-ferry-volant-les-transports-du-futur-pour-les-departs-en-vacances_AV-202307110521.html`,
          description: `"Un peu comme si on voyageait en train, sauf qu'à la fin on a quand même sa voiture sur le lieu d'arrivée."`,
        },
        {
          title: "Ouest France",
          photo: require("../assets/images/Capture-décran-2023-07-13-à-15.42.07.png"),
          logo: require("../assets/images/ui/ouest-france-logo.png"),
          url: `https://www.ouest-france.fr/leditiondusoir/2023-01-23/symone-ce-bus-qui-pourrait-revolutionner-notre-maniere-de-circuler-sur-l-autoroute-492cab84-a487-41e2-b5a2-551ece0beebe`,
          description: `"Les bus Symone rouleront à l'hydrogène et pendant le trajet, les voitures électriques pourront recharger leurs batteries."`,
        },
        {
          title: "AutoPlus",
          photo: require("../assets/images/Capture-décran-2023-07-13-à-15.44.27.png"),
          logo: require("../assets/images/ui/Autoplus-Logo.png"),
          url: `https://www.autoplus.fr/actualite/insolite/symone-bus-pourrait-revolutionner-autoroutes-627123.html?utm_campaign=post-auto&utm_medium=facebook&fbclid=IwAR2NFEFwHJWAle_2PXHmXRt_UPYUW4XJUeSIatZeHNXezhILLEPJzMlqITc`,
          description: `"Symone, le bus qui pourrait révolutionner les autoroutes."`,
        },
        {
          title: "Ici - France bleu",
          photo: require("../assets/images/Capture-décran-2023-07-13-à-16.11.08.png"),
          logo: require("../assets/images/ui/Capture-décran-2023-06-03-à-16.00.37.png"),
          url: `https://www.francebleu.fr/infos/transports/le-bus-dijonnais-symone-va-revolutionner-vos-voyages-sur-autoroute-8827502`,
          description: `Par France Bleu et France 3. "Le bus dijonnais Symone va révolutionner vos voyages sur autoroute".`,
        },
        {
          title: "Le Monde",
          photo: require("../assets/images/Capture-décran-2023-07-13-à-16.11.59.png"),
          logo: require("../assets/images/ui/Capture-décran-2023-06-03-à-15.53.53.png"),
          url: `https://www.lemonde.fr/idees/article/2022/01/26/il-faut-transformer-l-infrastructure-de-l-economie-pour-rendre-possible-une-sobriete-systemique_6111079_3232.html?fbclid=IwAR00gPHoJpKfZ5mKYCeO2NgQYEQAz8VUr3Uuu39IK-MLzI9TuXWN--cGkEU`,
          description: `Via la "Fabrique de la cité" : "Il faut transformer l'infrastructure de l'économie, pour rendre possible une sobriété systémique".`,
        },
        {
          title: "BFM Business",
          photo: require("../assets/images/Capture-décran-2023-07-13-à-16.08.21.png"),
          logo: require("../assets/images/ui/Capture-décran-2023-06-03-à-15.56.51.png"),
          url: `https://www.bfmtv.com/economie/replay-emissions/en-route-pour-demain/projet-simone-un-bus-pour-les-velos-et-les-voitures_VN-202303130474.html`,
          description: `"Projet Symone : un bus pour les motos et les voitures".`,
        },
        {
          title: "La Tribune",
          photo: require("../assets/images/Capture-décran-2023-07-13-à-16.09.31.png"),
          logo: require("../assets/images/ui/Capture-décran-2023-06-03-à-15.36.47.png"),
          url: `https://www.latribune.fr/regions/bourgogne-franche-comte/symone-reinvente-la-mobilite-durable-de-demain-856371.html`,
          description: `"Symone réinvente la mobilité durable de demain".`,
        },
      ],
      news: [],
      search: {
        depart: '',
        arrival: ''
      },
    };
  },
  created() {
    document.title= 'Accueil - Symone'
  },
  mounted() {
    this.getNews();
    //redirect to web version if width <= 1376
    this.checkScreenWidth();
    window.addEventListener("resize", this.checkScreenWidth);

    //control video
    const video = this.$refs.videoPlayer;

    video.addEventListener("loadedmetadata", () => {
      video.currentTime = 8; 
        video.play();
    });

    video.addEventListener("timeupdate", () => {
      if (video.currentTime >= 58) {
        
        video.currentTime = 8;
        video.play();
      }
    });

    document.querySelector(".prev-btn").addEventListener("click", () => {
      if (!this.allowTrigger) {
        this.showSlidesReverse();
      }
    });
    // 点击下一个按钮时切换到下一张图片
    document.querySelector(".next-btn").addEventListener("click", () => {
      if (!this.allowTrigger) {
        this.showSlides();
      }
    });
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      if (!this.allowTrigger) {
        this.showSlides();
      }
    }, 3000);

    //symone moto car animation
    document.addEventListener("DOMContentLoaded", function () {
      setTimeout(function () {
        document
          .querySelectorAll("#scrolling-container img")
          .forEach(function (img) {
            img.style.visibility = "visible";
          });
      }, 6000);
    });
  },
  beforeUnmount() {
    clearInterval(this.timer);
    window.removeEventListener("resize", this.checkScreenWidth);
  },
  methods: {
    async getNews(){
      try {
        let response = await axios.get(`${config.API_BASE_URL}/news/get/all`);
        this.news = JSON.parse(JSON.stringify(response.data));
        this.news = this.news.slice(-3);
        this.news.reverse();
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    },
    checkScreenWidth() {
      const screenWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (screenWidth >= 1376) {
        this.$router.push({ path: "/" });
      }
    },
    showSlides() {
      document.querySelectorAll(".slides-container").forEach((item) => {
        item.style.transition = "transform 0.5s ease"; // 重新设置过渡效果
      });
      this.allowTrigger = true;
      document
        .querySelectorAll(".slides-container")
        .forEach((item) => (item.style.transform = `translateX(-100%)`));
      setTimeout(() => {
        const sponsorList = document.querySelector(".sponsor-list");
        const slidesContainer = document.querySelector(
          ".sponsor-list .slides-container:first-child"
        );
        const targetNode = sponsorList.children[6]; // 获取父元素的第六个子节点（索引从0开始）
        sponsorList.insertBefore(slidesContainer, targetNode);
        document.querySelectorAll(".slides-container").forEach((item) => {
          item.style.transition = "none"; // 关闭过渡动画
          let transformValue = item.style.transform || "translateX(0%)"; // 初始值为0%
          let currentValue = parseInt(transformValue.match(/-?\d+/)[0]); // 获取当前的横坐标值
          let newValue = currentValue + 100; // 将当前横坐标值减去100
          item.style.transform = `translateX(${newValue}%)`; // 设置新的横坐标值
        });
        this.allowTrigger = false;
      }, 1000);
    },
    showSlidesReverse() {
      this.allowTrigger = true;

      let sponsorList = document.querySelector(".sponsor-list");
      let slidesContainer = document.querySelector(
        ".sponsor-list .slides-container:nth-child(5)"
      ); // 选择第五个子元素
      let targetNode = sponsorList.children[0]; // 获取父元素的第一个子节点
      sponsorList.insertBefore(slidesContainer, targetNode);

      sponsorList = document.querySelector(".sponsor-list");
      slidesContainer = document.querySelector(
        ".sponsor-list .slides-container:nth-child(5)"
      ); // 选择第五个子元素
      targetNode = sponsorList.children[0]; // 获取父元素的第一个子节点
      sponsorList.insertBefore(slidesContainer, targetNode);

      document.querySelectorAll(".slides-container").forEach((item) => {
        item.style.transition = "none"; // 关闭过渡动画
        let transformValue = item.style.transform || "translateX(0%)"; // 初始值为0%
        let currentValue = parseInt(transformValue.match(/-?\d+/)[0]); // 获取当前的横坐标值
        let newValue = currentValue - 200; // 将当前横坐标值+100
        item.style.transform = `translateX(${newValue}%)`; // 设置新的横坐标值
      });
      setTimeout(() => {
        document.querySelectorAll(".slides-container").forEach((item) => {
          item.style.transition = "transform 0.5s ease"; // 重新设置过渡效果
        });
        document
          .querySelectorAll(".slides-container")
          .forEach((item) => (item.style.transform = `translateX(-100%)`));
        this.allowTrigger = false;
      }, 1000);
    },
    scrollToTag2() {
      let elements = document.querySelectorAll(".full-screen-element");

      elements[1].scrollIntoView({ behavior: "smooth", block: "start" });
    },
    sendResarchOptions() {
      this.$router.push({
        name: 'Moto',
        query: { searchOptionsByHome: [this.search.depart, this.search.arrival] }
      });
    },
    hideCapitalRising() {
      var divToHide = document.getElementsByClassName("capital-raising");
      divToHide[0].style.display = "none";
    },
  },
};
</script>

<style scoped>
.border {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.border-white {
  text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff,
    1px 1px 0 #ffffff;
}

.menu a {
  color: white;
  margin: 0 10px;
}
.nav {
  padding-top: 25px;
  display: flex;
  width: 100%;
  justify-content: left;
  align-items: start;
  overflow-x: scroll;
  position: fixed;
}
.nav::-webkit-scrollbar {
  display: none; /* 隐藏滚动条 */
}

.nav::before {
  z-index: -1; /* 将阴影放置在元素的底部 */
  content: ""; /* 必须要有内容，才能显示伪元素 */
  position: absolute;
  top: 0; /* 放置在元素的顶部 */
  left: 0;
  width: 100%;
  height: 100%; /* 设置阴影高度 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    transparent
  );
}

.menu {
  display: flex;
}
.menu a {
  color: white;
  margin: 0 10px;
}
.footer {
  width: 100%;
  background-color: white;
  padding: 70px 20px 50px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}


.capital-raising {
  width: 520px;
  color: white;
  background: #42425178;
  border-radius: 30px;
  padding: 30px;
  text-align: left;
  margin-right: -45%;
  margin-top: 40px;
}
/**Tag-9 */
.home-tag-9 {
  height: 100vh;
  box-sizing: border-box;
  padding: 50px 20px;
  background-image: url("../assets/images/pencilsketchadjusted-7640102 (1).png");
  background-position: center;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: start;
  box-shadow: inset 5px 5px 200px 20px;
  overflow-y: scroll;
  position: sticky;
}
.home-tag-9 .card-row {
  background: #ffffffde;
  font-size: 20px;
  text-align: left;
  margin: 20px 0;
  max-width: 70vw;
}
.home-tag-9 img {
  width: -webkit-fill-available;
}
/**Tag-8 */
.home-tag-8 {
  height: 100vh;
  box-sizing: border-box;
  padding: 50px 20px;
  background-image: url("../assets/images/home-7.jpg");
  background-position: center left;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  align-items: center;
  place-content: center;
}
.extra-news-list {
  margin-top: 20px;
  height: 60vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.scrollable-container {
  display: flex;
  flex-direction: column;
}

.card-extra-news {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  flex: 0 0 auto;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
}
a {
  text-decoration: none;
  color: inherit;
}
/**tag-6 */

.home-tag-6 {
  height: 100vh;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  background-color: #000047;
  background-image: url("../assets/images/ui/home-5.png");
  background-position: start;
  background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .card-image{
        max-width: fit-content;
    }
    .card-content{
        text-align: center;
    }
    img{
        max-width: 30vw;
        max-height: 130px;
    }
}

.el-carousel__item{
    display: flex;
  border-radius: 10px;
  flex-wrap: wrap;
  background-image: url("../assets/images/ui/aile-couleur-transparant.png");
  background-position: bottom;
  justify-content: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
/**tag-7 */
.home-tag-7 {
  height: 100vh;
  padding: 70px 2px 0 2px;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: end;
  background-image: url("../assets/images/rAv5xEMzf2.png");
  background-position: center center;
  background-size: cover;
  position: sticky;
  box-shadow: inset 5px 5px 80px 20px black;
}

.card-row {
  background: white;
  border-radius: 20px;
  padding: 10px;
  margin: 10px;
  text-align: -webkit-center;
}
#scrolling-container {
  height: 15vh;
  display: flex;
  width: 100%;
  overflow: hidden;
  position: absolute;
}

#scrolling-container img {
  height: 10vh;
  align-self: end;
  animation: scrollImage 15s linear infinite;
  animation-delay: 6s;
  visibility: hidden;
}

@keyframes scrollImage {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(300%);
  }
}
.sponsor-list {
  width: 200vw;
  max-width: 100%;
  overflow: hidden; /* 隐藏溢出内容 */
  position: relative; /* 相对定位 */
  display: -webkit-inline-box;
  -webkit-box-align: center;
  box-sizing: border-box;
}

.slides-container {
  display: flex; /* 使用 flex 布局 */
  transition: transform 0.5s ease; /* 添加过渡效果 */
  padding: 0 60px;
  overflow: hidden;
  width: 70vw;
}

.slides-container img {
  width: 100%; /* 图片宽度占满容器 */
  height: fit-content;
}

.prev-btn,
.next-btn {
  position: absolute; /* 绝对定位 */
  top: 50%; /* 按钮垂直居中 */
  transform: translateY(-50%); /* 调整垂直位置 */
  background: transparent; /* 背景透明 */
  border: none; /* 去掉边框 */
  outline: none; /* 去掉外边框 */
  cursor: pointer; /* 鼠标样式设置为手型 */
}

.prev-btn {
  left: 0; /* 左侧按钮 */
}

.next-btn {
  right: 0; /* 右侧按钮 */
}
/**tag-5 */
.home-tag-5-list {
  display: flex;
  flex-wrap: wrap;
}

.card-price {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 10px;
  border-radius: 10px;
  background-color: white;
  justify-content: center;
  text-align: center;
  padding: 10px;
}

.card-image {
  border-radius: 10px;
  align-self: center;
  margin: 0 10px;
}

.card-image img {
  max-width: 80vw;
  height: auto; 
  border-radius: 10px;
}

.card-content {
  padding: 0 10px;
  align-self: center;
}
.home-tag-5 {
  padding: 20px 2px;
  width: 100%;
  box-sizing: border-box;
  background-color: #000047;
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: sticky;
}

.firstname {
  color: white;
  font-weight: 400;
  font-size: 40px;
}
.lastname {
  color: white;
  font-weight: 400;
  font-size: 20px;
}
.avatar {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  border-radius: 50%; /* 使图像成为圆形 */
}
/**Tag-4 */
.card {
  background-color: #000047;
  background-image: url("../assets/images/ui/aile-bleu-s.png");
  width: 250px;
  height: 400px;
  background-size: 100% auto;
  background-position: start;
  background-repeat: no-repeat;
  border-radius: 10px;
  margin: 10px;
  display: inline-block;
}
.card-bg {
  display: flex;
  flex-direction: column;
  height: 100%;
  place-content: center;
  align-items: center;
}

.card:hover {
  background-image: url("../assets/images/ui/aile-rose.png");
}
.home-tag-4 {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  box-sizing: border-box;
  background-image: url("../assets/images/ui/aile-bleu.png");
  background-position: center;
  background-size: cover;
  position: sticky;
  overflow-y: scroll;
}

/**Tag-3 */
.home-tag-3 {
}
.material-symbols-outlined {
  font-size: 60px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.home-tag-3-details {
  background: #00000047;
  border-radius: 10px;
}

.home-tag-3-loop {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-weight: 300;
  font-size: 4vw;
  width: 100%;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  padding: 50px 20px;
  box-shadow: inset 5px 5px 80px 20px black;
}

.home-tag-3-loop-1 {
  background-image: url("../assets/images/chaise-roulante.jpg");
}
.home-tag-3-loop-2 {
  background-image: url("../assets/images/SYMONE2.jpg");
}
.home-tag-3-loop-3 {
  background-image: url("../assets/images/Image6-1536x1119-1.jpeg");
}
.home-tag-3-loop-4 {
  background-image: url("../assets/images/SYMONE3.jpg");
}
.home-tag-3-loop-5 {
  background-image: url("../assets/images/Image8_modif2.png");
}
.home-tag-3-title {
  margin-bottom: 10px;
}
.slideshow {
  width: 100%;
}
/*Tag-2*/
.home-tag-2 {
  width: 100%; /* 宽度占满父容器 */
  height: 100vh; /* 高度占满视口 */
  overflow: hidden; /* 隐藏溢出内容 */
  position: relative; /* 相对定位，用于放置视频 */
}

.home-tag-2 video {
  position: absolute; /* 绝对定位，覆盖整个容器 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* 视频填充整个容器 */
}

.overlay {
  position: absolute; /* 相对定位 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  text-align: center;
  justify-content: start;
  padding-top: 150px;
}

/*tag-1*/
>>> .autocomplete input {
  border-radius: 5px;
  padding: 15px;
}
input {
  border-radius: 5px;
  padding: 15px;
}
#homeFilter {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  position: relative;
}
.btn-rose {
  border: 1px solid #000047;
  background-color: #e6007e;
  color: white;
  border-radius: 5px;
  padding: 13px 20px;
  font-weight: 400;
  font-size: 4vw;
}

.btn-trans-blue {
  background-color: transparent;
  color: #000047;
  font-size: 20px;
  border: 2px solid #000047;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}
.btn-trans-blue:hover {
  background-color: #000047;
  color: white;
}
.btn-trans-white {
  background-color: transparent;
  color: white;
  font-size: 4vw;
  font-weight: 200;
  margin-top: 20px;
  width: 30vw;
  border: 1px solid white;
  padding: 5px 8px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s; /* 添加过渡效果 */
}
.btn-trans-white:hover {
  background-color: white; /* 悬浮时背景色变为白色 */
  color: black; /* 悬浮时文字变为透明 */
}
.home-tag-1-autocar {
  background-image: url("../assets/images/SYMONE-visuel.png");
  background-position: bottom left;
  background-size: cover;
  width: 100%;
  box-sizing: border-box;
  box-shadow: inset 5px 5px 160px 20px;
  position: sticky;
  height: 50vh;
  overflow-x: hidden;
}
.home-tag-1-moto {
  background-position: bottom center;
  background-image: url("../assets/images/photo_2024-03-01_15-47-58.jpg");
  background-size: cover;
  box-sizing: border-box;
  box-shadow: inset 5px 5px 160px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 50vh;
  place-content: start;
  align-items: self-end;
}
.home-tag-1-filter {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}
.home-tag-1-top {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
}
.home-tag-1-bottom {
  margin: 20px 20px 0 20px;
}
.title {
  color: white;
  font-size: 5vw;
  font-weight: 400;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.home-tag-1-top-title {
  text-align: right;
}
.home-tag-1-bottom-title {
  text-align: left;
}
.details {
  color: white;
  font-size: 4vw;
  font-weight: 300;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.home-tag-1-top-details {
  text-align: right;
}
.home-tag-1-bottom-details {
  text-align: left;
}
@media screen and (max-width: 1088px) {
  .capital-raising {
    display: none;
  }
  .capital-raising-m {
    background-image: url("../assets/images/ui/aile-couleur-transparant.png");
    display: block !important;
    width: 100%;
    color: #000047;
    background-color: #c3c3c3;
    padding: 30px;
    text-align: left;
    background-size: cover;
    box-sizing: border-box;
  }
}
@media screen and (min-width: 464px) {
  .title {
    font-size: 30px;
  }

  .title-l {
    font-size: 40px !important;
    width: 100%;
  }

  .details {
    font-size: 20px;
  }

  .btn-trans-white {
    font-size: 20px;
  }

  .btn-rose {
    font-size: 20px;
  }

  .card-content img {
    max-width: 300px;
  }

  .card-extra-news {
    max-width: 320px;
  }

  .card-image img {
    max-height: 200px;
    max-height: 30%;
  }

  .card-price {
    max-width: 60vw;
  }

  .home-tag-1-autocar {
    height: 60vh;
  }

  .home-tag-1-moto {
    height: 40vh;
  }

  .home-tag-1-filter {
    flex-direction: row;
  }

  .home-tag-3-details {
    max-width: 70vw;
    place-self: center;
  }

  .home-tag-4 {
    height: auto;
  }

  .home-tag-5-list {
    place-content: center;
  }

  .card-row {
    width: 60vw;
  }

  .card-image img {
    max-width: 250px;
    max-height: 150px;
  }

  .home-tag-9 .card-row {
    width: 100%;
    max-width: 100%;
    display: flex;
  }

  .slides-container {
    width: 20vw;
    padding: 0 100px;
  }
  .home-tag-6{
    padding: 50px;
  }
}
</style>

<template>
  <div id="container">
    <div class="home-tag-1 full-screen-element">
      <div class="nav" style="position: fixed; z-index: 2;">
        <a href="/en" target="_self"
          ><img
            src="../assets/images/ui/logo.png"
            style="margin-left: -100px; height: fit-content; max-width: 250px"
        /></a>
        <div class="menu details">
          <a href="/en" target="_self">Home</a>
          <a href="/moto" target="_self">Symone by motorcycle</a>
          <a href="/news" target="_self">News</a>
          <a href="/contact" target="_blank"
            >Contact</a
          >
          <a href="/faq" target="_blank">FAQ</a>
          <a href="/" target="_self"
            ><img
              src="../assets/images/ui/france.png"
              style="width: 25px; height: 25px"
          /></a>
          <a href="/en" target="_self"
            ><img
              src="../assets/images/ui/royaume-uni.png"
              style="width: 25px; height: 25px"
          /></a>
        </div>
      </div>
      <div class="home-tag-1-autocar">
        <div class="home-tag-1-top">
          <div class="home-tag-1-top-title title border">
            Welcome aboard Symone! From 2025...
          </div>
          <div style="display: flex; max-width: 40vw; align-items: center">
            <div class="home-tag-1-top-details details">
              Symone rethinks long-distance highway travel by combining free
              time, ecology, and road safety.
            </div>
            <div
              @click="scrollToTag2()"
              class="btn-trans-white"
              style="width: auto; text-align: center; margin: 0 0 0 10px"
            >
              Learn more
            </div>
          </div>
        </div>
      </div>
      <div class="home-tag-1-moto">
        <div class="home-tag-1-bottom">
          <div class="home-tag-1-bottom-title title border">
            ...and starting today for motorcyclists!
          </div>
          <div style="display: flex; max-width: 40vw; align-items: center">
            <div
              class="btn-trans-white"
              style="width: auto; text-align: center; margin: 0 10px 0 0"
            >
              <a href="/moto" target="_self">Learn more</a>
            </div>
            <div class="home-tag-1-bottom-details details">
              Symone transports you and your motorcycle to the gates of your
              playgrounds.
            </div>
          </div>
        </div>
        <div class="home-tag-1-filter" style="display: flex">
          <form id="homeFilter">
            <Autocomplete
              class="autocomplete"
              v-model="search.depart"
              placeholder="FROM"
            ></Autocomplete>
            <Autocomplete
              class="autocomplete"
              v-model="search.arrival"
              placeholder="TO"
            ></Autocomplete>
          </form>
          <button class="btn-rose" style="float: right" @click='sendResarchOptions'>Search</button>

          <div id="scrolling-container">
            <img src="../assets/images/voiture-remorque.png" alt="car" />
          </div>
        </div>
      </div>
    </div>
    <div class="home-tag-2 full-screen-element">
      <video autoplay muted loop>
        <source
          src="../assets/videos/SYMONE_SHORT_V2_SANS_TEXTES_COUPE - Compressed with FlexClip.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <div class="overlay">
        <div class="title border" style="max-width: 80vw">
          Symone, a new road experience
        </div>
        <div class="details" style="max-width: 80vw">
          Symone offers a new ecological, shared, and unique mode of travel on
          the highway. Symone is a large hydrogen-powered bus, allowing you to
          travel with your vehicle without driving it.
        </div>
        <div
          class="capital-raising"
          data-id="9be358d"
          data-element_type="container"
          id="popup"
          data-settings='{"content_width":"full","background_background":"classic","position":"absolute"}'
        >
          <div style="width: 100%; display: flex; justify-content: end; z-index: 4;">
            <svg
              @click="hideCapitalRising()"
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="none"
              viewBox="0 0 22 22"
            >
              <circle
                cx="11"
                cy="11"
                r="10"
                stroke="#fff"
                stroke-width="1.5"
              ></circle>
              <path
                stroke="#fff"
                stroke-linecap="round"
                stroke-width="1.5"
                d="m7 7 7.071 7.071m0-7.071L7 14.071"
              ></path>
            </svg>
          </div>
          <h2 class="elementor-heading-title elementor-size-default">
            Did you miss our last capital raising ?
          </h2>
          <br />
          <p data-testid="ouah_q1yiAk5EyZMYlCKe">
            Following several requests and an additional institutional
            investment opportunity, we are keeping open a second subscription
            until the end of June 2024 only. At the same conditions but with a
            very limited amount of maximum 200,000 euros!
          </p>
          <br />
          <p data-testid="ouah_q1yiAk5EyZMYlCKe">
            Don't delay, last chance to become a shareholder of Symone starting from 500 euros :
          </p>
          <p>
            <span style="color: #e6007e"
              ><strong
                ><a
                  style="color: #e6007e"
                  href="https://sowefund.com/projet/177/symone"
                  >https://sowefund.com/projet/177/symone</a
                ></strong
              ></span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="home-tag-7 full-screen-element">
      <div style="width: 100%; padding: 0 50px; height: 60vh">
        <div class="card-row">
          <div class="card-image">
            <img src="../assets/images/Group-52.png" alt="Image" />
          </div>
          <div class="card-content">
            <h3 style="font-size: 25px; margin: 10px 0; color: #000047">
              Easy booking
            </h3>
            <p style="margin-bottom: 10px; color: #000047">
              Booking will be simple and convenient: with just a few clicks,
              thanks to the Symone application, you can book your ticket for
              your vehicle, regardless of the number of passengers. As easy as
              booking a train ride.
            </p>
          </div>
        </div>
        <div class="card-row" style="float: right">
          <div class="card-content">
            <h3 style="font-size: 25px; margin: 10px 0; color: #000047">
              Fast boarding
            </h3>
            <p style="margin-bottom: 10px; color: #000047">
              Prior to the highway toll, professionals will load your car or
              motorcycle while you settle into the comfortable cabin of your
              Symone. Transparent loading time for you to start enjoying the
              numerous onboard services.
            </p>
          </div>
          <div class="card-image">
            <img src="../assets/images/SYMONE2.jpg" alt="Image" />
          </div>
        </div>
        <div class="card-row" style="float: left">
          <div class="card-image">
            <img src="../assets/images/Image6-1536x1119-1.jpeg" alt="Image" />
          </div>
          <div class="card-content">
            <h3 style="font-size: 25px; margin: 10px 0; color: #000047">
              Time saved
            </h3>
            <p style="margin-bottom: 10px; color: #000047">
              During the journey, manage your time and enjoy the
              services offered on board. An electric car? Make your Symone a
              range extender!
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="home-tag-3 full-screen-element">
      <div class="slideshow" id="slideshow">
        <div
          class="home-tag-3-loop"
          @click="selectText(0)"
          style="align-self: start"
        >
          <div class="home-tag-3-logo">
            <span class="material-symbols-outlined"> accessible </span>
          </div>
          <div class="home-tag-3-title title">Accessibility</div>
          <div class="home-tag-3-details">
            A Symone can accommodate up to 20 passengers, including several
            people with reduced mobility (PRM).
          </div>
        </div>
        <div class="home-tag-3-loop" @click="selectText(1)">
          <div class="home-tag-3-logo">
            <span class="material-symbols-outlined"> eco </span>
          </div>
          <div class="home-tag-3-title title">Ecology</div>
          <div class="home-tag-3-details">
            Operating on green hydrogen, a Symone emits only a few drops of
            water in use and contributes to the decarbonization of our roads.
            During operation, up to 550 tons of CO2 less per year and per
            circulating Symone H2! The prototype will run on bioCNG (gas).
          </div>
        </div>
        <div
          class="home-tag-3-loop"
          @click="selectText(2)"
          style="align-self: end"
        >
          <div class="home-tag-3-logo">
            <span class="material-symbols-outlined"> lock </span>
          </div>
          <div class="home-tag-3-title title">Safety</div>
          <div class="home-tag-3-details">
            Our experienced driver will take you safely to your destination on
            the highway network, day and night.
          </div>
        </div>
        <div class="home-tag-3-loop" @click="selectText(3)">
          <div class="home-tag-3-logo">
            <span class="material-symbols-outlined"> hail </span>
          </div>
          <div class="home-tag-3-title title">Convenience</div>
          <div class="home-tag-3-details">
            You quickly board a Symone directly at the toll booth with your
            vehicle, with as much luggage and people as you want. A Symone can
            transport 6 to 10 vehicles (cars or motorcycles). Enjoy your long
            highway journeys to rest, for leisure, to spend time with family, or
            to work.
          </div>
        </div>

        <div
          class="home-tag-3-loop"
          @click="selectText(4)"
          style="align-self: start; margin-top: 150px"
        >
          <div class="home-tag-3-logo">
            <span class="material-symbols-outlined"> concierge </span>
          </div>
          <div class="home-tag-3-title title">Service</div>
          <div class="home-tag-3-details">
            Travel worry-free in comfortable compartmentalized cabins equipped
            with multiple services (wifi, video screens, comfortable
            armchairs...) for an optimal experience. Convenience and catering
            are also available.
          </div>
        </div>
      </div>
    </div>

    <div class="home-tag-9 full-screen-element">
      <div style="padding: 0 50px; text-align: -webkit-center">
        <div class="border-white" style="font-size: 50px; margin-bottom: 50px">
          <b
            ><span style="color: #e6007e">F</span>rom 2025, a first vehicle in
            circulation</b
          >
        </div>
        <div class="card-row">
          <div class="card-image">
            <img src="../assets/images/PARTENARIATS.png" alt="Image" />
          </div>
          <div class="card-content">
            <p style="margin-bottom: 10px; color: #000047">
              Symone is a long vehicle powered by decarbonized hydrogen waiting
              for you on your route, at the toll booth. We load your car or
              motorcycle, and you get on the front of the Symone in a
              comfortable passenger cabin, like a first-class train. On two
              levels, a Symone accommodates up to 20 passengers downstairs in a
              comfortable space and carries up to 10 cars and motorcycles.
            </p>
          </div>
        </div>
        <div class="card-row">
          <div class="card-content">
            <p style="margin-bottom: 10px; color: #000047">
              Starting in 2025, with a vehicle running on BioCNG, Symone will
              offer a new road experience, carefree. Day or night, imagine the
              comfort of being transported on the highway to arrive fresh and
              rested at your destination with your vehicle. You could sleep,
              work, or spend more time with your family. On now safer and more
              decarbonized journeys. <br /><br />Symone is a new ecological and
              shared mode of transportation for both individuals and
              professionals. A great tool for ecological transition. It is also
              mobility for territories, for everyone and more inclusive.
            </p>
          </div>
          <div class="card-image">
            <img src="../assets/images/PARTENARIATS (1).png" alt="Image" />
          </div>
        </div>
      </div>
    </div>

    <div class="home-tag-4 full-screen-element">
      <div
        style="
          height: 100%;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          align-items: center;
          text-align-last: center;
          padding: 50px;
          height: 100%;
          box-sizing: border-box;
          background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.6)
          );
        "
      >
        <div
          class="home-tag-4-card"
          style="display: flex; flex-wrap: wrap; justify-content: center"
        >
          <div class="card">
            <div class="card-bg">
              <img class="avatar" src="../assets/images/romain.jpg" />
              <div class="firstname">Romain</div>
              <div class="lastname">Coispine</div>
              <div
                class="post"
                style="color: #e6007e; margin-top: 20px; margin-bottom: 20px"
              >
                CEO
              </div>
              <a
                href="https://www.linkedin.com/in/romain-coispine-311b0a174/"
                target="_blank"
                ><i
                  class="fab fa-linkedin"
                  style="color: white; font-size: 40px"
                ></i
              ></a>
            </div>
          </div>
          <div class="card">
            <div class="card-bg">
              <img class="avatar" src="../assets/images/1662033571674.jpeg" />
              <div class="firstname">Yoann</div>
              <div class="lastname">Lacombe</div>
              <div
                class="post"
                style="color: #e6007e; margin-top: 20px; margin-bottom: 20px"
              >
                COO
              </div>
              <a
                href="https://www.linkedin.com/in/yoann-lacombe-6b8679ab/"
                target="_blank"
                ><i
                  class="fab fa-linkedin"
                  style="color: white; font-size: 40px"
                ></i
              ></a>
            </div>
          </div>
        </div>
        <div
          class="home-tag-4-details"
          style="width: 50%; padding: 50px; color: white; text-align-last: left"
        >
          <div style="font-size: 50px; margin-bottom: 15px">
            <b><span style="color: #e6007e">T</span>he founders</b>
          </div>
          <div style="font-size: 18px">
            Yoann and Romain met through their volunteer commitment to the
            French Young Economic Chamber in Dijon. They led general interest
            projects there and naturally found themselves in this business
            creation project. It was, among other things, the values of
            collectivity and responsibility that brought them together.
            <br /><em
              >"We know that the path to creating a business is long and full of
              obstacles. With commitment, humility, and audacity, we approach
              each difficulty as an opportunity and attempt the impossible."</em
            ><br />
            Three main development axes have driven them for 2 years to see this
            project as a "raison d'être" rather than just a business creation,
            however innovative it may be. "With Symone, we can make our highways
            safer, greener, and decarbonized."
          </div>
        </div>
      </div>
    </div>
    <div class="home-tag-5 full-screen-element">
      <div
        style="
          margin-top: 40px;
          font-size: 50px;
          font-weight: 600;
          color: white;
          text-align-last: center;
        "
      >
        <span style="color: #e6007e">P</span>rices &
        <span style="color: #e6007e">A</span>wards
      </div>
      <div class="home-tag-5-list">
        <div class="card-price" v-for="(item, index) in arr_price" :key="index">
          <div class="card-image">
            <img :src="item.url" alt="Image" />
          </div>
          <div class="card-content">
            <h3 style="font-size: 25px; margin-bottom: 10px">
              {{ item.title }}
            </h3>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="home-tag-6 full-screen-element" id="home-tag-6">
      <div
        style="
          font-size: 20px;
          font-weight: 300;
          color: white;
          float: right;
          text-align: right;
        "
        class="border"
      >
        <a href="/news" target="_self"><u>See all news</u></a>
      </div>
      <div class="news-list">
                
        <el-carousel :interval="4000" type="card" height="300px" width="100%">
            <el-carousel-item v-for="item in news" :key="item">
                <div class="card-image">
                    <img :src="`${config.API_BASE_URL}/assets/images/news/${item.cover}`" alt="Cover" />
                </div>
                <div class="card-content">
                    <h3 style="font-size: 25px; margin-bottom: 10px; color: #000047">
                    {{ item.title }}
                    </h3>
                    <button class="btn-trans-blue"><a :href="`/news/${item.id}`" target="_blank">Learn more</a></button>
                </div>
            </el-carousel-item>
        </el-carousel>
      </div>
      <div
        style="
          font-size: 60px;
          font-weight: 600;
          color: white;
          margin-top: 20px;
        "
      >
        <span style="color: #e6007e">D</span>iscover our latest news
      </div>
    </div>
    <div class="home-tag-8 full-screen-element">
      <div class="extra-news-list">
        <div class="scrollable-container">
          <div
            class="card-extra-news"
            v-for="(item, index) in arr_extra_news"
            :key="index"
          >
            <div class="card-image">
              <img :src="item.logo" alt="Image" />
            </div>
            <a :href="item.url" target="_blanc"
              ><div class="card-content">
                <h3 style="font-size: 25px; margin: 10px 0; color: #000047">
                  {{ item.title }}
                </h3>
                <p style="margin-bottom: 10px; color: #000047">
                  {{ item.description }}
                </p>
                <img
                  :src="item.photo"
                  style="max-width: 280px"
                  alt="Image"
                /></div
            ></a>
          </div>
        </div>
      </div>
      <div
        style="
          margin-right: auto;
          margin-top: 30px;
          font-size: 60px;
          color: #000047;
          font-weight: 600;
          color: white;
        "
      >
        <span style="color: #e6007e">T</span>hey talk about us
      </div>
    </div>
    <div class="footer full-screen-element">
      <div style="margin: 50px; width: -webkit-fill-available">
        <div
          style="
            font-size: 60px;
            font-weight: 600;
            color: #000047;
            text-align: center;
          "
        >
          <span style="color: #e6007e">T</span>hey support us
        </div>
        <div
          style="
            margin-top: 10px;
            margin-bottom: 80px;
            color: #000047;
            font-size: 18px;
            text-align: center;
          "
        >
          Symone can count on the support of major players in economic
          development and mobility.
        </div>
        <div class="sponsor-list">
          <div
            class="slides-container"
            v-for="(item, index) in arr_sponsors"
            :key="index"
          >
            <img
              :src="item"
              alt="Logo"
              style="display: inline-block; max-width: 200px"
            />
          </div>
          <button class="prev-btn">&lt;</button>
          <button class="next-btn">&gt;</button>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            margin-top: 80px;
          "
        >
          <img
            src="@/assets/images/ui/logo_couleur (1).png"
            alt="Logo"
            style="width: 200px; height: fit-content; align-self: center"
          />

          <div
            style="
              font-size: 20px;
              color: #000047;
              font-weight: 400;
              text-align-last: center;
            "
          >
            <br />
            <a href="/" target="_blank">Welcome on board</a>
            <br /><br />
            <a href="/moto" target="_blank">Symone by motorcycle</a>
            <br /><br />
            <a href="/en/#home-tag-2" target="_blank"
              >A new road experience</a
            >
            <br /><br />
            <a
              href="/condition"
              target="_blank"
              >General Terms and Conditions of Sale</a
            >
            <br />
          </div>

          <div
            style="
              font-size: 20px;
              color: #e6007e;
              font-weight: 400;
              text-align-last: center;
            "
          >
            FOLLOW US
            <br />
            <a href="https://fr.linkedin.com/company/symone" target="_blank"
              ><i
                class="fab fa-linkedin"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <a href="https://twitter.com/SymoneMobility" target="_blank"
              ><i
                class="fab fa-twitter"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <a
              href="https://www.instagram.com/symonemobility/?hl=fr"
              target="_blank"
              ><i
                class="fab fa-instagram"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <a href="https://www.facebook.com/SymoneMobility/" target="_blank"
              ><i
                class="fab fa-facebook"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <br />
            <br />
            CONTACT US
            <br />
            <span style="color: #000047"
              >contact@symone.fr<br />+33 6 17 33 43 93</span
            >
            <br />
            <br />
            WRITE TO US
            <br />
            <a href="/contact" target="_blanc"
              ><span style="color: #000047">Contact</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Autocomplete from "@/components/Autocomplete.vue";
import axios from 'axios';
import config from '@/config';

export default {
  name: "HomePageEN",
  components: {
    Autocomplete,
  },
  data() {
    return {
      config: config,
      allowTrigger: false,
      homeDepart: null,
      homeArrival: null,
      items: [],
      currentIndex: 0,
      currentTag: 0,
      slideIndex: 0,
      timer: "",
      timerSponsor: "",
      images: [
        "chaise-roulante.jpg",
        "SYMONE2.jpg",
        "Image6-1536x1119-1.jpeg",
        "SYMONE3.jpg",
        "Image8_modif2.png",
      ],
      arr_sponsors: [
        require("@/assets/images/ui/Logo-Ademe-2020-qe5vewri8j2htnbjzkwgr486pdu5ahuodrnho0n93o.png"),
        require("@/assets/images/ui/Logo_Vinci-Autoroutes-e1685543038498-qe5vend4c6pmljp7igu726lkrj4h5itd0h4mv91534.png"),
        require("@/assets/images/ui/Logo_France_Relance-qe5vewri8j2htnbjzkwgr486pdu5ahuodrnho0n8sk.png"),
        require("@/assets/images/ui/Logo_Bpifrance-e1685543068296-qe5vend4c6pmljp7igu726lkrj4h5itd0h4mv91552.png"),
        require("@/assets/images/ui/logoBFC-qe5vewri8j2htnbjzkwgr486pdu5ahuodrnho0n7ya.png"),
        require("@/assets/images/ui/Capture-décran-2023-06-03-à-15.06.52.png"),
      ],
      arr_price: [
        {
          title: "ESUS Label",
          url: require("../assets/images/ui/Capture-décran-2023-06-03-à-14.59.04.png"),
          description: `Issued by the Ministry of Economy, Finance, and Digital Sovereignty.`,
        },
        {
          title: "Future Vehicle Label",
          url: require("../assets/images/ui/Capture-décran-2023-06-03-à-15.06.52.png"),
          description: `The Future Vehicle Pole is an association that promotes synergy between companies, education, and research in the field of future vehicles and mobility.`,
        },
        {
          title: "Hydrogénies 2022 Laureate",
          url: require("../assets/images/ui/Capture-décran-2023-06-03-à-15.16.41.png"),
          description: `"Heartfelt and disruptive project" prize at the 2022 Hydrogen Trophies at the National Assembly.`,
        },
        {
          title: 'Finalist "10K Startups to Change the World"',
          url: require("../assets/images/ui/Capture-décran-2023-06-03-à-15.24.10.png"),
          description: `Symone wins the regional final of the competition organized by La Tribune and BNP Paribas, in the Start category.`,
        },
        {
          title: "Easy Mobility Challenge",
          url: require("../assets/images/ui/logo_1_.png"),
          description: `"Symone" finishes second in the Easy Mobility Challenge organized by CANAL+ and SEAT.`,
        },
        {
          title: "BPI France Deeptech Member",
          url: require("../assets/images/ui/Capture-décran-2023-06-05-à-11.33.55.png"),
          description: `Selected in Nov. 2022, Symone is a member of "the ecosystem of startups that are changing the world through disruptive innovation".`,
        },
        {
          title: "The European Parliament",
          url: require("../assets/images/ui/Thearch.png"),
          description: `Symone was presented at the European Parliament in the 100 solutions for the planet.`,
        },
        {
          title: "Michelin International Laureate",
          url: require("../assets/images/ui/movinon.png"),
          description: `"A striking example of safe and efficient mobility that meets the expectations of the end user and supports a viable business model for environmentally friendly travel."`,
        },
      ],
      arr_extra_news: [
        {
          title: "Europe 1",
          photo: require("../assets/images/Capture-décran-2023-07-13-à-14.36.13-1.png"),
          logo: require("../assets/images/ui/Europe1_FLAT.png"),
          url: `https://www.europe1.fr/emissions/la-france-bouge-academie/symone-propose-des-bus-pour-transporter-des-vehicules-et-leurs-passagers-sur-lautoroute-4188380`,
          description: `"Symone offers buses to transport vehicles and their passengers on the highway."`,
        },
        {
          title: "RMC STORY",
          photo: require("../assets/images/Capture-décran-2023-07-13-à-15.37.29.png"),
          logo: require("../assets/images/ui/RMC-Story-logo.png"),
          url: `https://rmc.bfmtv.com/actualites/tech/des-voitures-sur-le-toit-d-un-bus-un-ferry-volant-les-transports-du-futur-pour-les-departs-en-vacances_AV-202307110521.html`,
          description: `"A bit like traveling by train, except at the end you still have your car at the destination."`,
        },
        {
          title: "Ouest France",
          photo: require("../assets/images/Capture-décran-2023-07-13-à-15.42.07.png"),
          logo: require("../assets/images/ui/ouest-france-logo.png"),
          url: `https://www.ouest-france.fr/leditiondusoir/2023-01-23/symone-ce-bus-qui-pourrait-revolutionner-notre-maniere-de-circuler-sur-l-autoroute-492cab84-a487-41e2-b5a2-551ece0beebe`,
          description: `"Symone buses will run on hydrogen and during the journey, electric cars can recharge their batteries."`,
        },
        {
          title: "AutoPlus",
          photo: require("../assets/images/Capture-décran-2023-07-13-à-15.44.27.png"),
          logo: require("../assets/images/ui/Autoplus-Logo.png"),
          url: `https://www.autoplus.fr/actualite/insolite/symone-bus-pourrait-revolutionner-autoroutes-627123.html?utm_campaign=post-auto&utm_medium=facebook&fbclid=IwAR2NFEFwHJWAle_2PXHmXRt_UPYUW4XJUeSIatZeHNXezhILLEPJzMlqITc`,
          description: `"Symone, the bus that could revolutionize highways."`,
        },
        {
          title: "Ici - France bleu",
          photo: require("../assets/images/Capture-décran-2023-07-13-à-16.11.08.png"),
          logo: require("../assets/images/ui/Capture-décran-2023-06-03-à-16.00.37.png"),
          url: `https://www.francebleu.fr/infos/transports/le-bus-dijonnais-symone-va-revolutionner-vos-voyages-sur-autoroute-8827502`,
          description: `By France Bleu and France 3. "The Dijon bus Symone will revolutionize your journeys on the highway".`,
        },
        {
          title: "Le Monde",
          photo: require("../assets/images/Capture-décran-2023-07-13-à-16.11.59.png"),
          logo: require("../assets/images/ui/Capture-décran-2023-06-03-à-15.53.53.png"),
          url: `https://www.lemonde.fr/idees/article/2022/01/26/il-faut-transformer-l-infrastructure-de-l-economie-pour-rendre-possible-une-sobriete-systemique_6111079_3232.html?fbclid=IwAR00gPHoJpKfZ5mKYCeO2NgQYEQAz8VUr3Uuu39IK-MLzI9TuXWN--cGkEU`,
          description: `Via the "City Factory": "We need to transform the infrastructure of the economy to make systemic sobriety possible".`,
        },
        {
          title: "BFM Business",
          photo: require("../assets/images/Capture-décran-2023-07-13-à-16.08.21.png"),
          logo: require("../assets/images/ui/Capture-décran-2023-06-03-à-15.56.51.png"),
          url: `https://www.bfmtv.com/economie/replay-emissions/en-route-pour-demain/projet-simone-un-bus-pour-les-velos-et-les-voitures_VN-202303130474.html`,
          description: `"Symone Project: a bus for motorcycles and cars".`,
        },
      ],
      news:[],
      search: {
        depart: '',
        arrival: ''
      },
    };
  },
  created() {
    
    document.title= 'Home - Symone'
  },
  mounted() {
    this.getNews();
    //redirect to mobile version if width < 1376
    this.checkScreenWidth();
    window.addEventListener("resize", this.checkScreenWidth);

    document.querySelector(".prev-btn").addEventListener("click", () => {
      if (!this.allowTrigger) this.showSlidesReverse();
    });
    // 点击下一个按钮时切换到下一张图片
    document.querySelector(".next-btn").addEventListener("click", () => {
      if (!this.allowTrigger) this.showSlides();
    });
    document.querySelectorAll(".home-tag-3-loop")[0].classList.add("active");
    document.getElementById("slideshow").style.backgroundImage =
      "url(" + require(`../assets/images/${this.images[0]}`) + ")";
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      // 移除所有文字的 active 类
      document
        .querySelectorAll(".home-tag-3-loop")
        .forEach((text) => text.classList.remove("active"));
      // 添加点击文字的 active 类
      document
        .querySelectorAll(".home-tag-3-loop")[this.currentIndex == 4 ? 0 : this.currentIndex + 1].classList.add(
          "active"
        );

      // 切换背景图
      document.getElementById("slideshow").style.backgroundImage =
        "url(" +
        require(`../assets/images/${
          this.images[this.currentIndex == 4 ? 0 : this.currentIndex + 1]
        }`) +
        ")";
      this.currentIndex = this.currentIndex == 4 ? 0 : this.currentIndex + 1;
    }, 8000);

    this.timerSponsor = setInterval(() => {
      this.slideIndex++;
      this.showSlides();
    }, 3000);

    const container = document.getElementById("container");
    const elements = document.querySelectorAll(".full-screen-element");
    let currentTag = 0; 
    let isScrolling = false;

    container.addEventListener("wheel", (event) => {
      event.preventDefault();

      if (!isScrolling) {
        const direction = event.deltaY > 0 ? 1 : -1;

        let minDistance = Infinity;
        elements.forEach((element, index) => {
          const distance = Math.abs(element.getBoundingClientRect().top);
          if (distance < minDistance) {
            minDistance = distance;
            currentTag = index;
          }
        });

        currentTag += direction;
        currentTag = Math.max(0, Math.min(currentTag, elements.length - 1));

        elements[currentTag].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });

        isScrolling = true;
        setTimeout(() => {
          isScrolling = false;
        }, 1400);
      }
    });





    //symone moto car animation
    document.addEventListener("DOMContentLoaded", function () {
      setTimeout(function () {
        document
          .querySelectorAll("#scrolling-container img")
          .forEach(function (img) {
            img.style.visibility = "visible";
          });
      }, 6000);
    });
  },
  beforeUnmount() {
    // 在组件销毁前清除定时器，避免内存泄漏
    clearInterval(this.timer);
    clearInterval(this.timerSponsor);
    window.removeEventListener("resize", this.checkScreenWidth);
  },
  methods: {
    async getNews(){
      try {
        let response = await axios.get(`${config.API_BASE_URL}/news/get/all`);
        this.news = JSON.parse(JSON.stringify(response.data));
        this.news = this.news.slice(-3);
        this.news.reverse();
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    },
    checkScreenWidth() {
      const screenWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (screenWidth < 1376) {
        this.$router.push({ path: "/m/en" });
      }
    },
    hideCapitalRising() {
      var divToHide = document.getElementsByClassName("capital-raising");
      divToHide[0].style.display = "none";
    },
    selectText(index) {
      // 移除所有文字的 active 类
      document
        .querySelectorAll(".home-tag-3-loop")
        .forEach((text) => text.classList.remove("active"));
      // 添加点击文字的 active 类
      document
        .querySelectorAll(".home-tag-3-loop")[index].classList.add("active");

      // 切换背景图
      document.getElementById("slideshow").style.backgroundImage =
        "url(" + require(`../assets/images/${this.images[index]}`) + ")";
      this.currentIndex = index;
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.slideIndex++;
        this.showSlides();
        // 移除所有文字的 active 类
        document
          .querySelectorAll(".home-tag-3-loop")
          .forEach((text) => text.classList.remove("active"));
        // 添加点击文字的 active 类
        document
          .querySelectorAll(".home-tag-3-loop")[this.currentIndex == 4 ? 0 : this.currentIndex + 1].classList.add(
            "active"
          );

        // 切换背景图
        document.getElementById("slideshow").style.backgroundImage =
          "url(" +
          require(`../assets/images/${
            this.images[this.currentIndex == 4 ? 0 : this.currentIndex + 1]
          }`) +
          ")";
        this.currentIndex = this.currentIndex == 4 ? 0 : this.currentIndex + 1;
      }, 8000);
    },
    showSlides() {
      document.querySelectorAll(".slides-container").forEach((item) => {
        item.style.transition = "transform 0.5s ease"; // 重新设置过渡效果
      });
      this.allowTrigger = true;
      document
        .querySelectorAll(".slides-container")
        .forEach((item) => (item.style.transform = `translateX(-100%)`));
      setTimeout(() => {
        const sponsorList = document.querySelector(".sponsor-list");
        const slidesContainer = document.querySelector(
          ".sponsor-list .slides-container:first-child"
        );
        const targetNode = sponsorList.children[6]; // 获取父元素的第六个子节点（索引从0开始）
        sponsorList.insertBefore(slidesContainer, targetNode);
        document.querySelectorAll(".slides-container").forEach((item) => {
          item.style.transition = "none"; // 关闭过渡动画
          let transformValue = item.style.transform || "translateX(0%)"; // 初始值为0%
          let currentValue = parseInt(transformValue.match(/-?\d+/)[0]); // 获取当前的横坐标值
          let newValue = currentValue + 100; // 将当前横坐标值减去100
          item.style.transform = `translateX(${newValue}%)`; // 设置新的横坐标值
        });
        this.allowTrigger = false;
      }, 1000);
    },
    showSlidesReverse() {
      this.allowTrigger = true;

      let sponsorList = document.querySelector(".sponsor-list");
      let slidesContainer = document.querySelector(
        ".sponsor-list .slides-container:nth-child(5)"
      ); // 选择第五个子元素
      let targetNode = sponsorList.children[0]; // 获取父元素的第一个子节点
      sponsorList.insertBefore(slidesContainer, targetNode);

      sponsorList = document.querySelector(".sponsor-list");
      slidesContainer = document.querySelector(
        ".sponsor-list .slides-container:nth-child(5)"
      ); // 选择第五个子元素
      targetNode = sponsorList.children[0]; // 获取父元素的第一个子节点
      sponsorList.insertBefore(slidesContainer, targetNode);

      document.querySelectorAll(".slides-container").forEach((item) => {
        item.style.transition = "none"; // 关闭过渡动画
        let transformValue = item.style.transform || "translateX(0%)"; // 初始值为0%
        let currentValue = parseInt(transformValue.match(/-?\d+/)[0]); // 获取当前的横坐标值
        let newValue = currentValue - 200; // 将当前横坐标值+100
        item.style.transform = `translateX(${newValue}%)`; // 设置新的横坐标值
      });
      setTimeout(() => {
        document.querySelectorAll(".slides-container").forEach((item) => {
          item.style.transition = "transform 0.5s ease"; // 重新设置过渡效果
        });
        document
          .querySelectorAll(".slides-container")
          .forEach((item) => (item.style.transform = `translateX(-100%)`));
        this.allowTrigger = false;
      }, 1000);
    },
    scrollToTag2() {
      let elements = document.querySelectorAll(".full-screen-element");

      elements[1].scrollIntoView({ behavior: "smooth", block: "start" });
    },
    sendResarchOptions() {
      this.$router.push({
        name: 'Moto',
        query: { searchOptionsByHome: [this.search.depart, this.search.arrival] }
      });
    }
  },
};
</script>

<style scoped>
svg {
  position: absolute;
}

.border {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.border-white {
  text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff,
    1px 1px 0 #ffffff;
}

.capital-raising {
  width: 520px;
  color: white;
  background: #42425178;
  border-radius: 30px;
  padding: 30px;
  text-align: left;
  margin-right: -45%;
  margin-top: 40px;
}

.menu a {
  color: white;
  margin: 0 10px;
}
.nav {
  padding-top: 25px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: start;
}

.nav::before {
  z-index: -1; /* 将阴影放置在元素的底部 */
  content: ""; /* 必须要有内容，才能显示伪元素 */
  position: absolute;
  top: 0; /* 放置在元素的顶部 */
  left: 0;
  width: 100%;
  height: 80px; /* 设置阴影高度 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    transparent
  ); /* 创建上部渐变背景 */
}

.menu {
  display: flex;
}
.menu a {
  color: white;
  margin: 0 10px;
}
.footer {
  height: 100vh;
  width: 100%;
  background-color: white;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.el-carousel__item{
    display: flex;
  border-radius: 10px;
  flex-wrap: wrap;
  background-image: url("../assets/images/ui/aile-couleur-transparant.png");
  background-position: bottom;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
/**Tag-9 */
.home-tag-9 {
  height: 100vh;
  box-sizing: border-box;
  padding: 50px;
  background-image: url("../assets/images/pencilsketchadjusted-7640102 (1).png");
  background-position: center;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  align-items: center;
  place-content: center;
  box-shadow: inset 5px 5px 200px 20px;
}
.home-tag-9 .card-row {
  background: #ffffffde;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 20px;
  text-align: left;
  margin: 20px 0;
  max-width: 70vw;
}
.home-tag-9 .card-image:hover {
  max-width: 500px;
  transition: max-width 0.5s ease;
}
.home-tag-9 img:hover {
  max-width: 500px;
  transition: max-width 0.5s ease;
}
/**Tag-8 */
.home-tag-8 {
  height: 100vh;
  box-sizing: border-box;
  padding: 50px;
  background-image: url("../assets/images/home-7.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  align-items: center;
  place-content: center;
}
.extra-news-list {
  margin-top: 90px;
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none; /* 隐藏IE和Edge的滚动条 */
  scrollbar-width: none; /* 隐藏Firefox的滚动条 */
}

.extra-news-list::-webkit-scrollbar {
  display: none; /* 隐藏Chrome和Safari的滚动条 */
}

.scrollable-container {
  display: flex;
  flex-wrap: nowrap;
}

.card-extra-news {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  flex: 0 0 auto;
  margin-right: 20px; /* 添加间距 */
  width: 300px; /* 设置每个卡片的宽度 */
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
}
a {
  text-decoration: none;
  color: inherit;
}
/**tag-6 */

.home-tag-6 {
  height: 100vh;
  padding: 50px;
  width: 100%;
  box-sizing: border-box;
  background-color: #000047;
  background-image: url("../assets/images/ui/home-5.png");
  background-position: start;
  background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .card-image{
        max-width: fit-content;
    }
    .card-content{
        text-align: center;
    }
    img{
        max-width: 30vw;
        max-height: 280px;
    }
}


.el-carousel__item{
    display: flex;
  border-radius: 10px;
  flex-wrap: wrap;
  background-image: url("../assets/images/ui/aile-couleur-transparant.png");
  background-position: bottom;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
/**tag-7 */
.home-tag-7 {
  height: 100vh;
  padding: 50px 50px 0 50px;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: end;
  background-image: url("../assets/images/rAv5xEMzf2.png");
  background-position: center center;
  background-size: cover;
  justify-content: center;
  box-shadow: inset 5px 5px 200px 20px;
}

.card-row {
  background: white;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  max-width: 60vw;
  margin: 10px;
}
#scrolling-container {
  height: 15vh;
  display: flex;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  position: absolute;
}

#scrolling-container img {
  height: 10vh;
  align-self: end;
  animation: scrollImage 15s linear infinite;
  animation-delay: 6s;
  visibility: hidden;
}

@keyframes scrollImage {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(300%);
  }
}
.sponsor-list {
  width: 200vw;
  max-width: 100%;
  overflow: hidden; /* 隐藏溢出内容 */
  position: relative; /* 相对定位 */
  display: -webkit-inline-box;
  -webkit-box-align: center;
  box-sizing: border-box;
}

.slides-container {
  display: flex; /* 使用 flex 布局 */
  transition: transform 0.5s ease; /* 添加过渡效果 */
  padding: 0 100px;
  overflow: hidden;
  width: 20vw;
}

.slides-container img {
  width: 100%; /* 图片宽度占满容器 */
  height: fit-content;
}

.prev-btn,
.next-btn {
  position: absolute; /* 绝对定位 */
  top: 50%; /* 按钮垂直居中 */
  transform: translateY(-50%); /* 调整垂直位置 */
  background: transparent; /* 背景透明 */
  border: none; /* 去掉边框 */
  outline: none; /* 去掉外边框 */
  cursor: pointer; /* 鼠标样式设置为手型 */
}

.prev-btn {
  left: 0; /* 左侧按钮 */
}

.next-btn {
  right: 0; /* 右侧按钮 */
}
/**tag-5 */
.home-tag-5-list {
  display: flex;
  flex-wrap: wrap;
}

.card-price {
  display: flex;
  width: calc(50% - 20px); /* 每个卡片的宽度为父容器的一半 */
  margin: 10px;
  border-radius: 10px;
  background-color: white;
}

.card-image {
  flex: 1;
  border-radius: 10px;
  align-self: center;
  margin-left: 10px;
  max-width: 200px;
}

.card-image img {
  max-width: 200px; /* 图片宽度占满容器 */
  height: auto; /* 高度自适应 */
  border-radius: 10px;
}

.card-content {
  flex: 1;
  padding: 0 10px;
  align-self: center;
}
.home-tag-5 {
  height: 100vh;
  padding: 50px;
  width: 100%;
  box-sizing: border-box;
  background-color: #000047;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.firstname {
  color: white;
  font-weight: 400;
  font-size: 40px;
}
.lastname {
  color: white;
  font-weight: 400;
  font-size: 20px;
}
.avatar {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  border-radius: 50%; /* 使图像成为圆形 */
}
/**Tag-4 */
.card {
  background-color: #000047;
  background-image: url("../assets/images/ui/aile-bleu-s.png");
  width: 250px;
  height: 400px;
  background-size: 100% auto;
  background-position: start;
  background-repeat: no-repeat;
  border-radius: 10px;
  margin: 0 10px;
  display: inline-block;
}
.card-bg {
  display: flex;
  flex-direction: column;
  height: 100%;
  place-content: center;
  align-items: center;
}

.card:hover {
  background-image: url("../assets/images/ui/aile-rose.png");
}
.home-tag-4 {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  box-sizing: border-box;
  background-image: url("../assets/images/ui/aile-bleu.png");
  background-position: center;
  background-size: cover;
}

/**Tag-3 */
.home-tag-3 {
  height: 100vh;
  box-sizing: border-box;
}
.material-symbols-outlined {
  font-size: 60px;
}

.home-tag-3-loop {
  flex: 1;
  text-align: center;
  cursor: pointer;
  transition: transform 0.5s, font-size 0.5s;
  height: 33vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  font-size: 18px;
  margin-top: 150px;
}

.active {
  font-size: 24px; /* 点击后放大文字 */
  text-shadow: -2px -2px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000; /* 描边效果，可以根据需要调整阴影的位置和颜色 */
}

.slideshow {
  padding: 50px;
  box-sizing: border-box;
  width: 100%;
  height: 100vh; /* 背景图容器的高度 */
  background-size: cover;
  background-position: center;
  transition: background-image 1s ease-in-out; /* 背景图过渡效果 */
  position: absolute; /* 相对定位 */
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin-bottom: 20px;
}
/*Tag-2*/
.home-tag-2 {
  width: 100%; /* 宽度占满父容器 */
  height: 100vh; /* 高度占满视口 */
  overflow: hidden; /* 隐藏溢出内容 */
  position: relative; /* 相对定位，用于放置视频 */
}

.home-tag-2 video {
  position: absolute; /* 绝对定位，覆盖整个容器 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* 视频填充整个容器 */
}

.overlay {
  position: absolute; /* 相对定位 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  text-align: center;
  justify-content: start;
  padding-top: 150px;
}

/*tag-1*/
>>> .autocomplete input {
  border-radius: 5px;
  padding: 15px;
}
input {
  border-radius: 5px;
  padding: 15px;
}
#homeFilter {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  position: relative;
}
.btn-rose {
  border: 1px solid #000047;
  background-color: #e6007e;
  color: white;
  border-radius: 5px;
  padding: 13px 20px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  font-size: 20px;
}

.btn-trans-white {
  font-family: "Roboto Condensed", sans-serif;
  background-color: transparent;
  color: white;
  font-size: 20px;
  margin-top: 20px;
  width: 30vw;
  border: 2px solid white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s; /* 添加过渡效果 */
}
.btn-trans-white:hover {
  background-color: white; /* 悬浮时背景色变为白色 */
  color: black; /* 悬浮时文字变为透明 */
}

.btn-trans-blue {
  background-color: transparent;
  color: #000047;
  font-size: 20px;
  border: 2px solid #000047;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}
.btn-trans-blue:hover {
  background-color: #000047;
  color: white;
}
.home-tag-1-autocar {
  background-position: center center; /* 垂直和水平居中 */
  background-image: url("../assets/images/SYMONE-visuel.png");
  height: 60vh;
  background-size: cover;
  font-family: "Roboto Condensed", sans-serif;
  padding: 50px;
  box-sizing: border-box;
  box-shadow: inset 5px 5px 200px 20px;
}
.home-tag-1-moto {
  background-position: bottom center; /* 垂直和水平居中 */
  background-image: url("../assets/images/photo_2024-03-01_15-47-58.jpg");
  height: 40vh;
  background-size: cover;
  padding: 50px;
  box-sizing: border-box;
  box-shadow: inset 5px 5px 200px 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.home-tag-1-filter {
  max-width: 50vw;
  position: static;
}
.home-tag-1-top {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  float: right;
  height: 100%;
}
.home-tag-1-bottom {
  height: 100%;
}
.title {
  max-width: 40vw;
  color: white;
  font-size: 60px;
  font-weight: 300;
  line-height: 55px;
  margin-bottom: 20px;
}
.home-tag-1-top-title {
  text-align: right;
}
.home-tag-1-bottom-title {
  text-align: left;
}
.details {
  max-width: 40vw;
  color: #dddddd;
  font-size: 20px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.home-tag-1-top-details {
  text-align: right;
}
.home-tag-1-bottom-details {
  text-align: left;
}
</style>
